import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { Tooltip, Grid, Typography, Paper, TextField, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ThemeColors } from '../Theme'
import { Global } from '../models/Global'

import { RestComponent } from 'react-frontend-utils' 

import { DateUtils, DateRangeSelector } from 'react-frontend-utils'

import Chart from "react-apexcharts";


export class StatisticsPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 30
        }
    };
    
    
    static currentYear = (new Date()).getFullYear();

    
     //options for the search time range pulldown
    _searchTimeRangeOptions = [
        {label: "Today", startTime: DateUtils.startOfToday().valueOf()},
        {label: "Last 2 days", startTime: DateUtils.startOfToday().valueOf() - (2 * DateUtils.MS_PER_DAY)},
        {label: "Last 7 days", startTime: DateUtils.startOfToday().valueOf() - (7 * DateUtils.MS_PER_DAY)},
        {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
        {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
        {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
        {label: "Year to Date", startTime: DateUtils.startOfYear().valueOf()},
        {label: "Previous Year", startTime: DateUtils.startOfYear(StatisticsPage.currentYear-1).valueOf(), endTime: DateUtils.lastDayOfYear(StatisticsPage.currentYear-1).valueOf()},
        {label: "Any", startTime: null}
    ];
    

    _datetimeFormatter = () => {
        return {
            year: 'yyyy',
            month: 'MMM yyyy',
            day: "MMM dd"
        };
    }
      
    _standardChartOptions = {
        stacked: true,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 600,
            animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
    };
    
    _dailyCountsChartOptions = {
      
        chart: this._standardChartOptions,
        colors: [ThemeColors.lighterBlue, ThemeColors.pink], 
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "90%"
            }
        },
        markers: {
            size: 2,
            strokeWidth: 0
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: this._datetimeFormatter()
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        },
        tooltip: {
            x: {
                format: "yyyy MMM dd"
            },
            y: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        }
    };

    _availableGroups;

    constructor(props) {
        super(props);
               
        this.state.searchFromDate = DateUtils.jsonDateString(DateUtils.startOfYear(), false);           //JsonDateString or null
        this.state.searchToDate = null;                                                                 //JsonDateString or null
        
        this.state.groupToSearch = null;            //Specific group to search for
        this.state.groupToSearchValue = null;

        this._availableGroups = Global.user.databases;

        this.state.dailyCountsChartData = null;  
        this.state.dailyMailingsChartData = null;
        this.state.totalEmailedCount = 0;
        this.state.totalPrintedCount = 0;
        this.state.totalMailings = 0;
        this.state.totalEmails = 0;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the first page of applications
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isSmall: window.innerWidth < 1200 });  
    }
    
    
    _fetchStats = () => {
        
        let queryString = "?";
        
        if (this.state.groupToSearch)
            queryString += "group=" + this.state.groupToSearch + "&";                

        if (this.state.searchFromDate)
            queryString += "fromDate=" + this.state.searchFromDate + "&";
        if (this.state.searchToDate) {
            
            const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
            queryString += "toDate=" + endOfDayDate + "&";
            
        }
                 
        this.incrementBusy();
        this.secureJSONFetch("/pr/jobs/stats", {},
                             this._fetchStatsCallback, this._fetchErrorCallback, queryString); 
        
    }
   
    
    //Callback for fetching Application Stats - response is json of ApplicationStats
    _fetchStatsCallback = (response) => {
        if (response) {            

            //Process Daily counts
            let totalPrintedCount = 0;
            let totalEmailedCount = 0;
            let totalMailings = 0;
            let totalEmailsSent = 0;

            const dailyPrintedSeries = [];
            const dailyEmailedSeries = [];
            const dailyMailingsSeries = [];
            const dailyEmailsSentSeries = [];
            for (let entry of response.dailyCounts) {
                dailyPrintedSeries.push([entry.day, entry.printedCount]);
                dailyEmailedSeries.push([entry.day, entry.emailedCount]);
                dailyMailingsSeries.push([entry.day, entry.mailings]);
                dailyEmailsSentSeries.push([entry.day, entry.emailsSent]);
                totalPrintedCount += entry.printedCount;
                totalEmailedCount += entry.emailedCount;
                totalMailings += entry.mailings;
                totalEmailsSent += entry.emailsSent;
            }            
            const dailyCountsChartData = [
                {
                  name: "Passes Printed",
                  data: dailyPrintedSeries
                },
                {
                  name: "ePasses Emailed",
                  data: dailyEmailedSeries
                }
            ];
            const dailyMailingsChartData = [
                {
                    name: "Mailings",
                    data: dailyMailingsSeries
                },
                {
                    name: "Emails Sent",
                    data: dailyEmailsSentSeries
                }
            ];

            this.setState({dailyCountsChartData: dailyCountsChartData, dailyMailingsChartData: dailyMailingsChartData,
                           totalPrintedCount: totalPrintedCount, totalEmailedCount: totalEmailedCount,
                           totalMailings: totalMailings, totalEmails: totalEmailsSent});


        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
  
 
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }
    
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    
    
    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
                  
        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}
                
                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>Calculate Statistics</Typography>  

                    <Grid container direction="row" spacing={3} style={{padding: 20}}>

                        <Grid item sm={6} xs={12}>

                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                dateFormat={null}
                                                timeOptions={this._searchTimeRangeOptions}
                                                minYear={2020}
                                                ref={this._dateRangeRef}
                                                initialTimeRange="Year to Date"
                                                initialStartDate={this.state.searchFromDate}
                                                initialEndDate={this.state.searchToDate}
                                                onDateChange={this._dateChangeCallback}
                                                onParseError={this._dateParseError}/>

                        </Grid>


                        <Grid item sm={6} xs={12}>

                            <div style={{width: '100%'}}>  

                                <Autocomplete
                                    size='small'
                                    value={this.state.groupToSearch}
                                    onChange={(event, newValue) => { this.setState({groupToSearch: newValue}); }}
                                    inputValue={this.state.groupToSearchValue}
                                    onInputChange={(event, newValue) => { this.setState({groupToSearchValue: newValue}); }}
                                    options={this._availableGroups}
                                    blurOnSelect
                                    renderInput={(params) => <TextField {...params} label="Community" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                /> 

                    

                            </div>                                    
                        </Grid>
                    </Grid>
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                     <Tooltip title="Calculate and Display Statistics below">
                            <Button fullWidth onClick={this._fetchStats} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label">
                                Calculate
                            </Button>
                        </Tooltip>

                    </div>

                </Paper>
                                
                {this.state.isBusy ? this.getBusyComponent('center', {padding: 15}, 30) : <div style={{height: 60}}/>}

                <div style={{marginTop: 15}}/>

                {this.state.dailyCountsChartData ? 
                    <div style={{display: this.state.dailyCountsChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant="button" align='center' style={{fontSize: 16}}>{"Daily Pass Counts (Totals: " + this.state.totalPrintedCount + " Printed, " + this.state.totalEmailedCount + " Emailed)"}</Typography>  
                        </div>
                        <Chart
                            options={this._dailyCountsChartOptions}
                            series={this.state.dailyCountsChartData}
                            type="bar"
                            height={window.innerHeight*0.40}
                        />  
                    </div>
                    : null
                }  

                <div style={{marginTop: 30}}/>

                {this.state.dailyMailingsChartData ?  
                    <div style={{display: this.state.dailyMailingsChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant="button" align='center' style={{fontSize: 16}}>{"Daily Mailings (Totals: " + this.state.totalMailings + " Mailings, " + this.state.totalEmails + " Emails Sent)"}</Typography>  
                        </div>
                        <Chart
                            options={this._dailyCountsChartOptions}
                            series={this.state.dailyMailingsChartData}
                            type="bar"
                            height={window.innerHeight*0.40}
                        />  
                    </div>
                    : null    
            }
                
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(StatisticsPage));


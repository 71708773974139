import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { Tooltip, Typography, Checkbox, Paper, TextField, Button, Collapse } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ThemeColors } from '../Theme'
import { Community } from '../models/Community'
import { Global } from '../models/Global'

import { RestComponent, Permissions } from 'react-frontend-utils' 

import { ManageTextField, ListSelectPopover, TextEntryPopover } from 'react-frontend-utils'

import { LogoSelector} from '../components/LogoSelector'
import { ElectronicPassBuilder } from '../components/ElectronicPassBuilder';
import { PhysicalPassBuilder } from '../components/PhysicalPassBuilder';

export class CommunityPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 5,
            marginBottom: 30,
            color: 'gray',
            fontSize: '14pt',
            flexGrow: 1
        },
        paper: {
            padding: 10,
            paddingBottom: 20
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 100,
            float: 'right',
            textTransform: 'uppercase',
            padding: 5, 
            marginRight: 10,
            marginTop: 2,
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
        roundedContainer: {
            border: '1px solid #CCCCCC', 
            borderRadius: '4px', 
            padding: 10,
            marginBottom: 20
        },
        containerLabel: {
            fontSize: 12, 
            color: 'gray', 
            marginLeft: 5
        }
    };
    

    _communities = [];                              //List of Community objects

    constructor(props) {
        super(props);
        this.state.communityNames = [];                 //All available community names
        this.state.selectedCommunityName = "";        //The currently displayed community name (use empty string, Autocomplete barfs on null 
        this.state.selectedCommunityNameValue = "";       
        this.state.creatingNew = false;
        
        this.state.selectedCommunity = null;        //The currently selected Community Object  
        
        this.state.communityToModify = null;        //The Community object being modified for upsert
        
        this.state.showDatabaseSelect = false;      //Popover to select a database is open
        this.state.availableDatabases = [];         //List of available databases to use for new Community (databases the user can see, less the ones that already have Communities)

        this.state.enableElectronicPasses = false;
        this.state.enablePhysicalPasses = false;

        this.state.windowWidth = window.innerWidth;

        this.state.ePassesEnabled = null;           //Fetch after selecting community, to get true or false
    }
    
    
   
    componentDidMount() {
        super.componentDidMount();
        this._fetchAll();
        window.addEventListener("resize", this._updateSize);
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ windowWidth: window.innerWidth });  
    }
    
    //Fetch all Communities
    _fetchAll = () => {
        this.setState({creatingNew: false, ePassesEnabled: null, selectedCommunity: null, selectedCommunityName: "", communityToModify: null, selectedCommunityNameValue: ""}); 
        this._fetchCommunities(); 
    }
    
    
    _fetchCommunities = () => {
        
        this.incrementBusy();
        this.secureJSONFetch("/pr/groups", {},
                             this._fetchCommunitiesCallback, this._fetchErrorCallback); 
        
    }
   
    
    //Callback for fetching Communities - response is a list of Community objects
    _fetchCommunitiesCallback = (response) => {
        if (response) {            
            this._communities = response.map(community => new Community(community));
            
            const communityNameList = this._communities.map(community => community.name);

            this.setState({communityNames: communityNameList});
        }            
        this.decrementBusy();
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
  
  
    _delete = () => {
        if (this.state.communityToModify) {
            this.showConfirmAlert("Confirm", 'Really Delete Community "' + this.state.communityToModify.name + '"? Any existing print jobs for this Community will fail.',
                                  'black', "Cancel", this._deleteCommunity, "Delete", 'red');      
        }
    
    }
    
    _deleteCommunity = () => {
    
        if (this.state.communityToModify) {
            this.incrementBusy();
            this.secureJSONFetch("/pr/groups/" + this.state.communityToModify.name, {method: "DELETE"},
                             this._deleteCallback, this._fetchErrorCallback); 
            
            
        }
    }
    
    _deleteCallback = () => {
        this.showConfirmAlert("Success", "Community \"" + this.state.communityToModify.name + "\" deleted" , 'green'); 
        this.decrementBusy();
        this._fetchAll();
    }
  
  
    //Post the current community to the server for upserting
    _save = () => {
        if (this.state.communityToModify) {
            console.log("Modifying Community: ", this.state.communityToModify);
        
            this.incrementBusy();
            this.secureJSONFetch("/pr/groups", {method: "POST", body: JSON.stringify(this.state.communityToModify)},
                                 this._saveCallback, this._fetchErrorCallback); 
            
            
        }
    }
    
    
    _saveCallback = () => {

        this.showConfirmAlert("Success", "Community \"" + this.state.communityToModify.name + "\" " + (this.state.creatingNew ? "created" : "updated") , 'green'); 
        this.decrementBusy();
        this._fetchAll();
    }
  
 
    
 
    //Callback when a Community is selected from the available list, display the selected community
    _selectCommunity = (event, newValue) => { 
        
        const selectedCommunityName = newValue;

        const c = this._communities.find(c => c.name === newValue);
        if (!c)
            return;
        
        const communityToModify = c;
        
        this.setState({selectedCommunityName: selectedCommunityName, 
                       selectedCommunityNameValue: selectedCommunityName, 
                       selectedCommunity: c, 
                       communityToModify: communityToModify, 
                       enableElectronicPasses: c.enableElectronicPasses,
                       enablePhysicalPasses: c.enablePhysicalPasses,
                       creatingNew: false,
                       ePassesEnabled: null
                     }); 


        this.incrementBusy();
        this.secureJSONFetch("/pr/groups/" + selectedCommunityName + "/checkEPassesEnabled", {},
                            this._checkEPassesCallback, this._checkEPassesErrorCallback); 
        

    }
    
    _checkEPassesCallback = (response) => {
        this.decrementBusy();
        this.setState({ePassesEnabled: true});
    }

    _checkEPassesErrorCallback = () => {
        this.decrementBusy();
        this.setState({ePassesEnabled: false});
    }

    
    //Callback when a database is selected from the popover for a new Community, create the new Community from the name and hide the popup
    _databaseSelected = (name) => {
        
        const newCommunity = Community.createNew(name);
        this.setState({selectedCommunity: newCommunity, communityToModify: newCommunity, creatingNew: true, showDatabaseSelect: false, enableElectronicPasses: false, enablePhysicalPasses: false});    
    }
    
    
    //When New button pressed, find databases that could have a new Community created for, and either show an error if none available, or show popup for 
    //user to select a database
    _createNew = () => {
        
        //See if there are any available databases that aren't already configured.  
        const availableDatabases = Global.user.databases.filter(database => {
            
            //See if our list of communities already includes the database
            const found = this._communities.find(c => c.name === database);
            
            return !found;  //if found, exclude from filtered list, otherwise include
        });
                
        if (availableDatabases.length === 0) {
            this.showConfirmAlert("No available databases", "No databases exist that do not already have a configured Community", 'red');
            return;
        }   
        else 
            this.setState({availableDatabases: availableDatabases, showDatabaseSelect: true});

    }



    _testEPass = (recipientEmail) => {
        if (this.state.communityToModify) {
            this.incrementBusy();

            const queryString = "?recipientEmail=" + encodeURIComponent(recipientEmail);

            this.secureJSONFetch("/pr/groups/" + this.state.communityToModify.name + "/testEPass", {method: "POST"},
                                this._testEPassCallback, this._fetchErrorCallback, queryString); 
            
        }
        this.setState({emailPopupOpen: false});
    }

    _testEPassCallback = () => {

        this.showConfirmAlert("Success", "Submitted test job for ePass for Community \"" + this.state.communityToModify.name + "\"" , 'green'); 
        this.decrementBusy();
    }


    _testPPass = () => {
        if (this.state.communityToModify) {
            this.incrementBusy();
            this.secureJSONFetch("/pr/groups/" + this.state.communityToModify.name + "/testPPass", {method: "POST"},
                                this._testPPassCallback, this._fetchErrorCallback); 
            
        }
    }

    _testPPassCallback = () => {

        this.showConfirmAlert("Success", "Submitted test job for Card Pass for Community \"" + this.state.communityToModify.name + "\"" , 'green'); 
        this.decrementBusy();
    }

    
    //Select Database Popover cancelled
    _cancelNew = () => {
        this.setState({selectedCommunity: null, creatingNew: false, showDatabaseSelect: false});            
    }
    
    //A field in the Community was changed, update the selected Community object with the new data
    _communityFieldChanged = (fieldName, userValue) => {
        this.state.communityToModify[fieldName] = userValue;  
        this.forceUpdate();
    }

    _fieldInputError = (error) => {
        this.showConfirmAlert("Error", error, 'red');
    }
    
    _enableEPasses = (enable) => {

        if (this.state.selectedCommunity.enableElectronicPasses !== enable) {  //changing from what is saved

            if (!enable) {
                this.showConfirmAlert("Confirm Disable", 'Disabling ePasses is uncommon. This will stop processing of any pending ePass jobs. Note: this will not remove the ePass feature from your account. Contact Sales instead to remove this feature.',
                                      'black', "Cancel", () => this._doModifyEPasses(enable), "Continue", 'red');   
                return;
            }

        }
        this._doModifyEPasses(enable);
    }


    _doModifyEPasses = (enable) => {
        this.state.communityToModify.enableElectronicPasses = enable;
        this.setState({enableElectronicPasses: enable});
    }


    _enablePPasses = (enable) => {

        if (this.state.selectedCommunity.enablePhysicalPasses !== enable) {  //changing from what is saved

            if (!enable) {
                this.showConfirmAlert("Confirm Disable", 'Disabling Physical Passes will prevent all future print job submissions. Contact Support to stop any currently queued print jobs.',
                                    'black', "Cancel", () => this._doModifyPPasses(enable), "Continue", 'red');   
                return;
            }
            else {
                this.showConfirmAlert("Confirm Enable", 'Enabling Physical Passes will enable print jobs triggered from PoolPass. Your account will be charged for each job printed. To enable pass printing triggered from processed Applications, contact your account representative.',
                                     'black', "Cancel", () => this._doModifyPPasses(enable), "Continue", 'red');   
                return;
            }

        }
        this._doModifyPPasses(enable);
    }


    _doModifyPPasses = (enable) => {
        this.state.communityToModify.enablePhysicalPasses = enable;
        this.setState({enablePhysicalPasses: enable});
    }

    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
        const buttonStyle = (activeColor, disabled) => {
            
            let color = disabled ? 'lightGray' : activeColor;
            return {borderColor: color, color: color, maxWidth: 100, textAlign: 'center'};
        };
       
        const showCommunity = this.state.selectedCommunityName || this.state.creatingNew ? true : false;
        const canCreateNew = Global.user.hasPermissionTo(Permissions.ADMINISTER_PASSES) && (this.state.creatingNew || this.state.selectedCommunity ? false : true);
        const canDelete = Global.user.hasPermissionTo(Permissions.ADMINISTER_PASSES) && (this.state.creatingNew || !this.state.selectedCommunity ? false : true);
  
        let ePassStatus = "";
        let ePassTextColor;
        if (this.state.ePassesEnabled === true) {
            ePassStatus = "ePass Feature Enabled";
            ePassTextColor = 'green';
        }
        else if (this.state.ePassesEnabled === false) {
            ePassStatus = "ePass Feature Disabled (contact Sales to activate)";
            ePassTextColor = 'red';
        }


        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}
                
                <ListSelectPopover isOpen={this.state.showDatabaseSelect} okCallback={this._databaseSelected} cancelCallback={this._cancelNew}
                                   title="Select Database" items={this.state.availableDatabases}/>

                                                                 
                <TextEntryPopover isOpen={this.state.emailPopupOpen} showSkip={false} multiline={false} title="Email Address for Test Pass Delivery" 
                                  okCallback={this._testEPass} cancelCallback={() => {this.setState({emailPopupOpen: false});}} />
 
                
                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'left', alignItems: 'center'}}>
                    <Autocomplete
                        size='small'
                        disabled={this.state.selectedCommunity !== null}
                        style={{width: '35%'}}
                        value={this.state.selectedCommunityName}
                        onChange={this._selectCommunity}
                        inputValue={this.state.selectedCommunityNameValue}
                        onInputChange={(event, newValue) => { this.setState({selectedCommunityNameValue: newValue}); }}
                        options={this.state.communityNames}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Community" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />            
                
                    <Tooltip title="Save Changes and Refresh">
                        <Button disabled={!showCommunity} fullWidth size='small' style={buttonStyle('green', !showCommunity)} 
                                onClick={this._save} variant="outlined" component="label">
                            Save
                        </Button>
                    </Tooltip>                    
                    <Tooltip title="Discard Changes and Clear">
                        <Button disabled={!showCommunity} fullWidth size='small' style={buttonStyle('black', !showCommunity)} 
                                onClick={this._fetchAll} variant="outlined" component="label">
                            Discard
                        </Button>
                    </Tooltip>
                    <Tooltip title="Create a new Community">
                        <Button disabled={!canCreateNew} fullWidth size='small' style={buttonStyle(ThemeColors.addColor, !canCreateNew)}
                        onClick={this._createNew} variant="outlined" component="label">
                            New
                        </Button>
                    </Tooltip>
                    
                    <Tooltip title="Delete Community">
                        <Button disabled={!canDelete} fullWidth size='small' style={buttonStyle('red', !canDelete)}
                        onClick={this._delete} variant="outlined" component="label">
                            Delete
                        </Button>
                    </Tooltip>

                    <Tooltip title="Submit a Test Job for the Electronic Pass">
                        <Button disabled={!canDelete} fullWidth size='small' style={buttonStyle('black', !canDelete)}
                                onClick={() => {this.setState({emailPopupOpen: true});}} variant="outlined" component="label">
                            EPass Test
                        </Button>
                    </Tooltip>

                    <Tooltip title="Submit a Test Job for the Physical Pass">
                        <Button disabled={!canDelete} fullWidth size='small' style={buttonStyle('black', !canDelete)}
                                onClick={this._testPPass} variant="outlined" component="label">
                            Card Test
                        </Button>
                    </Tooltip>
                       
                    {this.state.isBusy ? this.getBusyComponent('right', {marginLeft: 20}, 30) : null}

                </div>
                
                <div style={{marginTop: 15}}/>
                
                {this.state.selectedCommunity ?
                    <Paper style={this.styles.paper}>   

                        <Typography style={this.styles.paperLabel} variant="h6">{"Editing \"" + this.state.selectedCommunity.name + "\""}</Typography> 
                        
                        <ManageTextField label="Community Name on Passes"
                                         initialValue={this.state.selectedCommunity.extendedName}
                                         style={{marginTop: 20}}
                                         json="extendedName"  
                                         autoAccept={true}
                                         onFieldChange={this._communityFieldChanged}/>

                        <ManageTextField label="Special Handling Instructions"
                                         initialValue={this.state.selectedCommunity.specialHandling}
                                         style={{marginTop: 20}}
                                         json="specialHandling"  
                                         autoAccept={true}
                                         editable={Global.user.hasPermissionTo(Permissions.ADMINISTER_PASSES)}
                                         onFieldChange={this._communityFieldChanged}/>
                        
                        <Typography variant="body2" style={{...this.styles.containerLabel, marginTop: 20, marginBottom: 0}}>Community Logo</Typography>            
                        <div style={this.styles.roundedContainer}>
                            <LogoSelector previewWidth={100} image={this.state.communityToModify.logoImage} onError={this._fieldInputError}
                                          onChange={ (image) => {this._communityFieldChanged("logoImage", image)}}/>
                        </div>

                        <div style={{borderTop: '4px solid ' + ThemeColors.appBarBackground, marginTop: 40}}/>
                        <Typography variant='button' align='center' style={{color: ThemeColors.appBarBackground, fontSize: 20}}>Electronic Passes</Typography>                        

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginLeft: -10, marginTop: 10, marginBottom: 20}}>
                            <Checkbox checked={this.state.selectedCommunity.enableElectronicPasses} color='primary' 
                                       disabled={!this.state.ePassesEnabled}
                                       onChange={(event) => { this._enableEPasses(event.target.checked)}}/>
                            <Typography variant='body1' align='left' style={{color: this.state.ePassesEnabled ? 'black' : 'lightGray'}}>Enable Electronic Passes</Typography>                        
                            <Typography variant='body1' align='right' style={{fontStyle: 'italic', fontWeight: 500, marginLeft: 'auto', marginRight: 5, color: ePassTextColor}}>{ePassStatus}</Typography>                        
                        </div>    
                        
                        <Collapse in={this.state.enableElectronicPasses && this.state.ePassesEnabled}>
                            <div style={{marginLeft: 20}}>
                                <ElectronicPassBuilder community={this.state.communityToModify} windowWidth={this.state.windowWidth} onChange={() => this.forceUpdate()}/>           
                            </div>                                             
                        </Collapse>

                        <div style={{borderTop: '4px solid ' + ThemeColors.appBarBackground, marginTop: 40}}/>
                        <Typography variant='button' align='center' style={{color: ThemeColors.appBarBackground, fontSize: 20}}>Physical Card Passes</Typography>                        

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginLeft: -10, marginTop: 10, marginBottom: 20}}>
                            <Checkbox checked={this.state.selectedCommunity.enablePhysicalPasses} color='primary' 
                                       onChange={(event) => { this._enablePPasses(event.target.checked) }}/>                                  
                            <Typography variant='body1' align='left'>Enable Physical Passes</Typography>                        
                        </div>    
                        
                        <Collapse in={this.state.enablePhysicalPasses}>               
                            <div style={{marginLeft: 20}}>
                                <PhysicalPassBuilder community={this.state.communityToModify} windowWidth={this.state.windowWidth} onChange={() => this.forceUpdate()}/>           
                            </div>
                        </Collapse>
                    

                    </Paper>
                    
                    
                : null}
                
                <div style={{marginTop: 15}}/>
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(CommunityPage));






import React, { useState }  from 'react';

import { Grid, Typography, Checkbox, Tooltip } from '@material-ui/core'
import { ColorPicker } from 'react-frontend-utils'

import { renderFieldGroupEdit } from './PassEdit';
import { DynamicDataType } from '../components/PassField';
import { EPassPreview } from '../components/EPassPreview';

/**
 * Designer page for Electronic Passes.  The left side is the builder and the right side is the preview.
 * Caller must pass the following props:
 * 
 *  community:  the Community object whose electronic pass fields are being edited
 *  windowWidth: the current window width, to dynamically update as the window changes
 *  onChange: a callback function taking no arguments, that is called when any part of the pass changes (should call forceUpdate to update the preview)
 * 
 */


export function ElectronicPassBuilder(props) {

    const style = {
        checkbox: {display: 'flex', alignItems: 'center', marginLeft: -10},
        fieldLabel: {display: 'flex', alignItems: 'center'},
        fieldGroup: {border: '1px solid lightGray', borderRadius: 5, padding: 10, marginTop: 20}
    };

    const community = props.community;
    const [eSecondaryFields, setESecondaryFields] = useState(community.eSecondaryFields); 
    const [eAuxiliaryFields, setEAuxiliaryFields] = useState(community.eAuxiliaryFields); 
    const [eBackFields, setEBackFields] = useState(community.eBackFields); 
    const [showBarcode, setShowBarcode] = useState(community.eBarcode); 


    const MAX_FRONT_FIELDS = 3;
    const MAX_BACK_FIELDS = 4;

    const addSecondaryField = () => {
        community.eSecondaryFields = [...community.eSecondaryFields, {label: "Your Label", dynamicDataType: DynamicDataType.MEMBERSHIP_ID.enumName}];
        setESecondaryFields(community.eSecondaryFields);
        props.onChange();
    }

    const removeSecondaryField = () => {
        community.eSecondaryFields = community.eSecondaryFields.slice(0, -1);
        setESecondaryFields(community.eSecondaryFields);
        props.onChange();
    }

    const addAuxiliaryField = () => {
        community.eAuxiliaryFields = [...community.eAuxiliaryFields, {label: "Your Label", dynamicDataType: DynamicDataType.MEMBERSHIP_ID.enumName}];
        setEAuxiliaryFields(community.eAuxiliaryFields);
        props.onChange();
    }

    const removeAuxiliaryField = () => {
        community.eAuxiliaryFields = community.eAuxiliaryFields.slice(0, -1);
        setEAuxiliaryFields(community.eAuxiliaryFields);
        props.onChange();
    }

    const addBackField = () => {
        community.eBackFields = [...community.eBackFields, {label: "Your Label", dynamicDataType: DynamicDataType.FIXED.enumName}];
        setEBackFields(community.eBackFields);
        props.onChange();
    }

    const removeBackField = () => {
        community.eBackFields = community.eBackFields.slice(0, -1);
        setEBackFields(community.eBackFields);
        props.onChange();
    }


    //Render at 85% of the window when we are below "md" size, otherwise 40%
    let previewWidth = props.windowWidth < 960 ? props.windowWidth * 0.85 : props.windowWidth * 0.40;

    if (previewWidth > 420)  //limit max size
        previewWidth = 420;

    return (

        <Grid container spacing={2}>


            <Grid item xs={12} md={6}>
                
                <Tooltip title="When checked, the Wallet apps will never consider the ePass to have expired. If the Membership expires, the Member will still not be able to check in, but it will
                                remain available in the Wallet app. When unselected, the Wallet apps will consider the pass expired on the Membership expiration date.">
                    <div style={style.checkbox}>
                        <Checkbox checked={community.ePassNeverExpires} color='primary' 
                                    onChange={(event) => { community.ePassNeverExpires = event.target.checked; props.onChange(); }}/>                                  
                        <Typography variant='body1' align='left'>ePass Never Expires from Wallet apps</Typography>                        
                    </div> 
                </Tooltip>
                <div style={style.checkbox}>
                    <Checkbox checked={community.eEnablePatronImage} color='primary' 
                                onChange={(event) => { community.eEnablePatronImage = event.target.checked; props.onChange(); }}/>                                  
                    <Typography variant='body1' align='left'>Show Patron Image</Typography>                        
                </div> 

                <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>
                    <div style={style.checkbox}>
                        <Checkbox checked={showBarcode} color='primary' 
                                    onChange={(event) => { const checked = event.target.checked; 
                                                        setShowBarcode(checked);
                                                        community.eBarcode = checked;
                                                        props.onChange(); 
                                                        }}/>                                  
                        <Typography variant='body1' align='left'>Show Barcode</Typography>                        
                    </div> 
                    <div style={{...style.checkbox, display: community.eBarcode ? 'flex' : 'none' }}>
                        <Checkbox checked={community.eBarcodeShowValue} color='primary' disabled={!community.eBarcode} 
                                    onChange={(event) => { community.eBarcodeShowValue = event.target.checked; props.onChange(); }}/>                                  
                        <Typography variant='body1' align='left'>Show Barcode Value</Typography>                        
                    </div> 
                </div>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 10, marginTop: 10}}>
                    <ColorPicker initialColor={community.eFontColor} 
                                    disableAlpha={true}
                                    onChange={(color) => {community.eFontColor = color.hex; props.onChange(); }}/>
                    <Typography variant='body1' style={{marginRight: 20}}>Font Color</Typography>      

                    <ColorPicker initialColor={community.eBackgroundColor} 
                                    disableAlpha={true}
                                    onChange={(color) => {community.eBackgroundColor = color.hex; props.onChange(); }}/>
                    <Typography variant='body1' style={{marginRight: 20}}>Background Color</Typography>      
                </div>


                {renderFieldGroupEdit("Secondary", addSecondaryField, removeSecondaryField, eSecondaryFields, MAX_FRONT_FIELDS, props.onChange)}
                {renderFieldGroupEdit("Auxiliary", addAuxiliaryField, removeAuxiliaryField, eAuxiliaryFields, MAX_FRONT_FIELDS, props.onChange)}
                {renderFieldGroupEdit("Back", addBackField, removeBackField, eBackFields, MAX_BACK_FIELDS)}

            </Grid>

            <Grid item xs={12} md={6}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <EPassPreview passData={community} previewWidth={previewWidth}/>
                </div>
            </Grid>

        </Grid>
    );
}
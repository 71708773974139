import React, { useState }  from 'react';

import { Typography, Tooltip, IconButton } from '@material-ui/core'
import ReactCardFlip from 'react-card-flip';

import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

import { ThemeColors } from '../Theme'

import { sampleData } from './PassField';

//Arbitrary dimensions used in ZeldDesigner to dimension pass
const ZLD_WIDTH = 550;
const ZLD_HEIGHT = 700;
const ZLD_RADIUS = 30 / ZLD_HEIGHT; 

const ZLD_MARGIN = 30 / ZLD_HEIGHT;  //ratio to height

const ZLD_HDR_HEIGHT = 50 / ZLD_HEIGHT;  //ratio to height
const ZLD_LOGO_GAP = 10 / ZLD_WIDTH;  //ratio to width;

const ZLD_HDR_GAP = 45 / ZLD_HEIGHT;  //ratio to height
const ZLD_PRIMARY_AREA_HEIGHT = 175 / ZLD_HEIGHT;  //ratio to height
const ZLD_PRIMARY_TEXT_OFFSET_FROM_BOTTOM = 30 / ZLD_HEIGHT;
const ZLD_IMAGE_BORDER = 8 / ZLD_HEIGHT;
const ZLD_PRIMARY_GAP = 25 / ZLD_HEIGHT;  //ratio to height

const ZLD_SEC_AUX_AREA_HEIGHT = 85 / ZLD_HEIGHT;  //ratio to height

const ZLD_BOTTOM_AREA = 170 / ZLD_HEIGHT;
const ZLD_BARCODE_HEIGHT = 130 / ZLD_HEIGHT;  //ratio to height
const ZLD_BARCODE_HEIGHT_WITH_VALUE = 150 / ZLD_HEIGHT; //if value shown

const ZLD_BARCODE_LR_PAD = 25 / ZLD_WIDTH;  //ratio to width
const ZLD_BARCODE_GAP = 15 / ZLD_HEIGHT;  //ratio to height

const ZLD_BACK_GAP = 10 / ZLD_HEIGHT;

const ZLD_BARCODE_IMG_HEIGHT = 100 / ZLD_HEIGHT;
const ZLD_BARCODE_IMG_WIDTH = 390 / ZLD_WIDTH;

/**
 * Preview component for an Electronic Pass, front and back sides.  Provides an animation to flip between front and back sides.
 * Styling and relative dimensions based on the zeld designer template.
 * 
 * This is display only - no changes to the pass are made in this component
 * 
 * Caller must pass props:
 *  
 *  passData: object that contains all the fields of the Community object starting with "e".  Can be the Community object itself.
 *  previewWidth: desired render width, the height is scaled based on the aspect ratio of the pass
 * 
 */

export function EPassPreview(props) {

    const [isFlipped, setFlipped] = useState(false); //front is not flipped, back is flipped
 

    const data = props.passData;
    const width = props.previewWidth;

    const height = props.previewWidth * ZLD_HEIGHT/ZLD_WIDTH;   //height scales with width 

    //Outer pass style for front and back
    const passStyle = {
        height: height, 
        width: width, 
        border: '1px solid gray',
        borderRadius: width * ZLD_RADIUS,
        backgroundColor: data.eBackgroundColor,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19)'
    }

    //Inner margin
    const contentStyle = {
        padding: height * ZLD_MARGIN  //inset for all content
    }

    //Content of the header are at the top of the front of pass
    const headerContainerStyle = {
        height: height * ZLD_HDR_HEIGHT, 
        width: '100%', 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end'      //on bottom
    }

    //Logo container for the image and title
    const logoContainerStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: width * ZLD_LOGO_GAP,
        alignItems: 'center'
    }

    //Primary area container, for name and thumbnail image
    const primaryAreaContainerStyle = {
        height: height * ZLD_PRIMARY_AREA_HEIGHT, 
        marginTop: height * ZLD_HDR_GAP,
        marginBottom: height * ZLD_PRIMARY_GAP,
        width: '100%', 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end'      //on bottom
    }

    //Style for the secondary and auxiliary fields
    const secondaryAuxContainerStyle = {
        height: height * ZLD_SEC_AUX_AREA_HEIGHT, 
        width: '100%', 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'      //on bottom
    }

    const patronImageStyle = {
        border: (ZLD_IMAGE_BORDER * height) + 'px solid white',
        imageHeight: primaryAreaContainerStyle.height - (2 * ZLD_IMAGE_BORDER * height)
    }

    //Container for everything below the auxiliary field
    const bottomContainerStyle = {
        height: height * ZLD_BOTTOM_AREA,
        paddingLeft: width * ZLD_BARCODE_LR_PAD,
        paddingRight: width * ZLD_BARCODE_LR_PAD,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end'
    }

    //White area containing the barcode and value
    const barcodeAreaStyle = {
        height: height * (data.eBarcodeShowValue ? ZLD_BARCODE_HEIGHT_WITH_VALUE : ZLD_BARCODE_HEIGHT), 
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        borderRadius: passStyle.borderRadius/2
    }

    const barcodeImageStyle = {
        marginTop: height * ZLD_BARCODE_GAP,
        imageHeight: height * ZLD_BARCODE_IMG_HEIGHT,
        imageWidth: width * ZLD_BARCODE_IMG_WIDTH
    }



    //Content style of the white area on the back
    const backContentStyle = {
        borderRadius: width * ZLD_RADIUS / 2,
        marginTop: 0.33 * height - 2 * contentStyle.padding,
        height: height * 0.66,
        width: '100%',
        backgroundColor: 'white'
    }

    //Style for the back fields
    const backFieldStyle = {
        padding: height * ZLD_BACK_GAP
    }

    //Scaled text for values, where 1 is the scale of the Logo Title
    const valueText = (text, scale = 1, bold = false, align = 'left', color = data.eFontColor) => {
        return (
            <div style={{color: color, fontWeight: bold ? 'bold' : 'normal', textAlign: align, fontSize: scale * 32 * (height/ZLD_HEIGHT)}}>
                {text}
            </div>);
    }

    //Text for labels above a value
    const labelText = (text, align = 'left') => {
        return <div style={{fontWeight: 'bold', textAlign: align, fontSize: 20 * (height/ZLD_HEIGHT)}}>{text}</div>
    }


    const secAuxField = (field, index, lastIndex) => {

        let align;
        if (index === 0)
            align = 'left';
        else if (index === lastIndex)
            align = 'right';
        else
            align = 'center';

        return (
            <div key={index}>
                {field.label ? labelText(field.label, align) : null}
                {valueText(sampleData(field.dynamicDataType, field.fixedText), 0.6, false, align)}
            </div>
        );
    }


    const backField = (field, index, lastIndex) => {

        return (
            <div key={index}>
                {field.label ? <div style={{marginBottom: 4, color: 'gray', fontSize: 16 * (height/ZLD_HEIGHT)}}>{field.label}</div> : null}
                {valueText(sampleData(field.dynamicDataType, field.fixedText), 0.6, false, 'left', 'gray')}
                {index === lastIndex ? null : <div style={{borderBottom: '0.5px solid lightGray', marginTop: 15, marginBottom: 5}}/>}
            </div>
        );
    }



    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10}}>
                <Typography variant='h6' align='center' style={{color: 'gray', fontStyle: 'italic', marginRight: 10}}>{"Pass Preview: " + (isFlipped ? "Back" : "Front")}</Typography>
                <Tooltip title={"Flip to view " + (isFlipped ? "front" : "back") + " of pass"}>
                    <IconButton onClick={() => setFlipped(!isFlipped)}>
                        <CompareArrowsIcon fontSize='large' style={{color: ThemeColors.appBarBackground}}/>
                    </IconButton>
                </Tooltip>
            </div>

            <ReactCardFlip isFlipped={isFlipped}>

                <div style={passStyle}>  {/*--------------------------- FRONT OF PASS ------------------------------*/}
                    <div style={contentStyle}>

                        <div style={headerContainerStyle}>

                            <div style={logoContainerStyle}>
                                {data.logoImage ?
                                    <img src={data.logoImage} alt="logo" width={headerContainerStyle.height} height={headerContainerStyle.height}/>
                                    : <div style={{width: 1, height: headerContainerStyle.height}}/>
                                }
                                {valueText(data.extendedName, 1, true)}
                            </div>
                            {valueText("Jane", 0.8)}

                        </div>

                        <div style={primaryAreaContainerStyle}>

                            <div style={{marginBottom: height * ZLD_PRIMARY_TEXT_OFFSET_FROM_BOTTOM}}>
                                {valueText("Jane Doe", 1.6)}
                            </div>
                            {data.eEnablePatronImage ?
                                <img style={patronImageStyle} src="samplePatron.jpg" alt="patronImage" height={patronImageStyle.imageHeight}/>
                                : null
                            }
                        </div>

                        <div style={secondaryAuxContainerStyle}>
                            {data.eSecondaryFields.map((field, index) => secAuxField(field, index, data.eSecondaryFields.length -1))}
                        </div>

                        <div style={secondaryAuxContainerStyle}>
                            {data.eAuxiliaryFields.map((field, index) => secAuxField(field, index, data.eAuxiliaryFields.length -1))}
                        </div>

                        <div style={bottomContainerStyle}>
                            {data.eBarcode ? 
                                <div style={barcodeAreaStyle}>
                                    <img style={barcodeImageStyle} src="samplePDF417Barcode.jpg" alt="barcodeImage" width={barcodeImageStyle.imageWidth} height={barcodeImageStyle.imageHeight}/>
                                    {data.eBarcodeShowValue ? valueText("123456", 0.7, false, 'center', 'black') : null}
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>

                <div style={passStyle}>  {/*--------------------------- BACK OF PASS ------------------------------*/}
                    <div style={contentStyle}>
                        <div style={backContentStyle}>
                            <div style={backFieldStyle}>
                                {data.eBackFields.map((field, index) => backField(field, index, data.eBackFields.length -1))}
                            </div>
                        </div>
                    </div>
                </div>


            </ReactCardFlip>
        </div>
    );

}
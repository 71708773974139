import React, { useState } from 'react';

import { Typography, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ManageTextField, DateUtils } from 'react-frontend-utils';


/**
 * The PassField component represents a data field somewhere on a pass, consisting of a label and field data.  The data can either be 
 * dynamically calculated or fixed text.  The class represents the PassField java class.  The DynamicDataType enum from java is passed
 * as a string (enumName) as a key in the field. This component shows a pulldown menu of available DynamicDataTypes, the label text, and
 * fixed text only if the DynamicDataType is FIXED.
 * 
 * The caller must pass the following props:
 * 
 *  fieldLabel: a label indicating where this field is on the pass
 *  field: the PassField object with keys of label, dynamicDataType, and fixedText
 *  onChange: callback function taking no arguments when something changes in this component
 * 
 */

const nextYear = DateUtils.currentYear() + 1;


export const DynamicDataType = {

    FIXED:              {enumName: "FIXED", label: "Fixed"},  
    MEMBERSHIP_ID:      {enumName: "MEMBERSHIP_ID", label: "Membership ID", sampleData: "DOE12345", operandType: "string"},  
    MEMBERSHIP_TYPE:    {enumName: "MEMBERSHIP_TYPE", label: "Membership Type", sampleData: "Resident", operandType: "string"},
    EXPIRATON_DATE:     {enumName: "EXPIRATON_DATE", label: "Expiration Date", sampleData: nextYear + " JAN 01", operandType: "date", format: "YYYY-MM-DD"},
    ADDRESS:            {enumName: "ADDRESS", label: "Address", sampleData: "123 Main Street Baltimore MD 01234", operandType: "string"},
    BIRTHDATE:          {enumName: "BIRTHDATE", label: "Birthdate", sampleData: "1950 JUN 20", operandType: "date", format: "YYYY-MM-DD"},
    AGE:                {enumName: "AGE", label: "Age", operandType: "integer", format: "number"},          
    RELATION:           {enumName: "RELATION", label: "Relation", sampleData: "Mother", operandType: "string"},
    EMERGENCY_CONTACT:  {enumName: "EMERGENCY_CONTACT", label: "Emergency Contact", sampleData: "Hospital 911", operandType: "string"},
    YEAR_PRINTED:       {enumName: "YEAR_PRINTED", label: "Year Printed", sampleData: DateUtils.currentYear(), operandType: "integer", format: "YYYY"},
};

export function sampleData(enumName, fixedText) {

    const dType = getDynamicDataTypeFromEnum(enumName);
    if (!dType)
        return "";

    if (dType === DynamicDataType.FIXED)
        return fixedText;
    else if (dType === DynamicDataType.BIRTHDATE) {
        const birthdate = DynamicDataType.BIRTHDATE.sampleData;
        try {
            const date = DateUtils.parseDate(birthdate, DateUtils.DateFormatType.ISO8601);  
            if (date.getFullYear() <= 1900)
                return "--";
            return dType.sampleData
        } catch (error) {
            return "?";
        }
    }
    else if (dType === DynamicDataType.AGE) {    // calculate age from birthdate
        const birthdate = DynamicDataType.BIRTHDATE.sampleData;

        try {
            const date = DateUtils.parseDate(birthdate, DateUtils.DateFormatType.ISO8601);  
            let age = DateUtils.calculateAge(date);
                            
            if (date.getFullYear() <= 1900)
                age = "--";
            return age;
        } catch (error) {
            return "?";
        }
    }
    else
        return dType.sampleData;
}

export function setSampleData(enumName, newSampleData) {
    const dType = getDynamicDataTypeFromEnum(enumName);
    if (!dType) {
        console.error("No Dynamic Data Type: " + enumName);
        return;
    }
    dType.sampleData = newSampleData;
}

export function sampleDataHasError(dType) {
    
    switch (dType.operandType) {
        case "date":
            try {
                DateUtils.parseDate(dType.sampleData, DateUtils.DateFormatType.ISO8601, true);
                return false;
            } catch (error) {
                return true;    // could not parse the date
            }
        case "integer":
            const sampleDataInt = parseInt(dType.sampleData);
            return isNaN(sampleDataInt);  //NaN if can't parse the integer
        default:
            return false;   // strings don't have errors
    }
}


// Evaluate the Operand to see if the logic applies for the given operator. Return true/false on the logic eval, null on invalid logic (bad operand)
export function evaluate(enumName, operator, operand) {
    const dType = getDynamicDataTypeFromEnum(enumName);
    if (!dType) {
        console.error("No Dynamic Data Type: " + enumName);
        return null;
    }

    let sampleDataVal = sampleData(enumName);

    //Convert dates to integers
    if (dType.operandType === "date") {
        try {
            sampleDataVal = DateUtils.parseDate(sampleDataVal, DateUtils.DateFormatType.ISO8601, true).getTime();
            operand = DateUtils.parseDate(operand, DateUtils.DateFormatType.ISO8601, true).getTime();
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    if (dType.operandType === "integer") {
        if (sampleDataVal === '--')     // handle when age calculation gives --
            sampleDataVal = Number.MAX_SAFE_INTEGER;
        operand = parseInt(operand);
        sampleDataVal = parseInt(sampleDataVal);
        if (isNaN(operand) || isNaN(sampleDataVal))
            return null;
    }

    switch (operator) {
        case "EQUALS":
            return operand === sampleDataVal;

        case "CONTAINS":
            if (dType.operandType !== "string")
                return null;
            return sampleDataVal.includes(operand);

        case "STARTS_WITH":
            if (dType.operandType !== "string")
                return null;
            return sampleDataVal.startsWith(operand);

        case "ENDS_WITH":
            if (dType.operandType !== "string")
                return null;
            return sampleDataVal.endsWith(operand);

        case "GREATER_THAN":
        case "LESS_THAN":
            if (dType.operandType === "string")
                return null;

            return operator === "GREATER_THAN" ? sampleDataVal > operand :  sampleDataVal < operand;

        default:
            console.error("Unknown operator: " + operator);
            return null;
    }

}


//Get the DynamicDataType object from the supplied enum (undefined if not found)
export function getDynamicDataTypeFromEnum(str) {

    for (const type of Object.values(DynamicDataType)) {
        if (str === type.enumName)
            return type;
    }
}

//Get the DynamicDataType object from the supplied String (undefined if not found)
export function getDynamicDataTypeFromLabel(str) {

    for (const type of Object.values(DynamicDataType)) {
        if (str === type.label)
            return type;
    }
}



export function PassField(props) {

    const field = props.field;

    const initialType = getDynamicDataTypeFromEnum(field.dynamicDataType);

    const [dynType, setDynType] = useState(initialType ? initialType.label : null); 
    const [dynTypeVal, setDynTypeVal] = useState(initialType ? initialType.label : ""); 

    //Set the enum based on the label
    const selectType = (event, newTypeLabel) => {
        if (!newTypeLabel)
            return;
        setDynType(newTypeLabel);
        const type = getDynamicDataTypeFromLabel(newTypeLabel);
        props.field.dynamicDataType = type.enumName;
        props.onChange();
    }

    //Based on the label, get the enum, and disable Fixed Text input if enum is not FIXED
    const type = getDynamicDataTypeFromLabel(dynType)
    const typeIsFixed = type && type.enumName === DynamicDataType.FIXED.enumName;

    //Get all the labels for the Autocomplete
    const options = Object.values(DynamicDataType).map(type => type.label);

    return (

        <div>
            <Typography variant='body2' style={{fontWeight: 'bold', marginBottom: 15}}>{props.fieldLabel}</Typography>

            <ManageTextField label="Field Label"                              
                                json="label"  
                                initialValue={props.field.label}
                                autoAccept={true}
                                onFieldChange={(fieldName, userValue) => {
                                    props.field.label = userValue;
                                    props.onChange();
                                    }}/>

            <div style={{display: 'flex', marginTop: 10, gap: 10}}>

                <Autocomplete
                    size='small'
                    disableClearable
                    style={{width: '50%'}}
                    value={dynType}
                    onChange={selectType}
                    inputValue={dynTypeVal}
                    onInputChange={(event, newValue) => { setDynTypeVal(newValue) }}
                    options={options}
                    blurOnSelect
                    renderInput={(params) => <TextField {...params} label="Data Type" variant="outlined" InputLabelProps={{ shrink: true }} />}
                />     

                <div style={{display: typeIsFixed ? 'block' : 'none', flexGrow: 2}}>
                    <ManageTextField label="Fixed Text"                              
                                    json="fixed"  
                                    multiline={3}
                                    initialValue={props.field.fixedText}
                                    autoAccept={true}
                                    onFieldChange={(fieldName, userValue) => {
                                        props.field.fixedText = userValue;
                                        props.onChange();
                                    }}/>
                </div>
            </div>
        </div>
    );
}

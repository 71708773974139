import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import queryString from 'query-string';

import Home from './routes/Home';


function App() {
  return (
    <div className="App">
      <CookiesProvider>
        <Router>
            <Switch>

                <Route path='/' exact={true} render={ () => {
                    return <Redirect to="/passportal"/>;
                }}/>
              

                <Route path='/passportal' exact={true} render={ (props) => {
                    let query = queryString.parse(props.location.search);
                    
                    return <Home id={query.id}/>;  //arrive to Access Granted portal, potentially with an job id
                      
                }}/>
                        
            </Switch>
        </Router>
      </CookiesProvider>
    </div>
  );
}

export default App;


//Use this to send to an external website (safely!) in a new tab
export function ExternalLink(props) {
    
    return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.target}</a>;
    
}


//Be careful here! XSS attack possible with url.  Use only on same site.  newWindow.opener is not cleared by this function
export function OpenInNewTab(url) {
    const newWindow = window.open(url);
    newWindow.applicationTab = window;
}

import { DateUtils } from 'react-frontend-utils'

export class Community {
      
    name;                           //Name of the community   
    version;                        
     
    extendedName;                   
    logoImage;                      //base64

    enableElectronicPasses;
    enablePhysicalPasses;

    bulkMail;
    bulkMailAddress;
    lastBulkMailDate;

    specialHandling;

    //Fields below are settings for Electronic Passes
    ePassNeverExpires;          //true or false
    eFontColor;
    eBackgroundColor;
    eSecondaryFields;           //array of pass field object with keys: label, dynamicDataType, and fixedText
    eAuxiliaryFields;           //array of PassField object with keys: label, dynamicDataType, and fixedText
    eBackFields;                //array of PassField object with keys: label, dynamicDataType, and fixedText
    eEnablePatronImage;         //true to enable
    eBarcode;                   //true to enable
    eBarcodeShowValue;          //true to show the value

    //Fields below are settings for PhysicalPasses
    pOrientationIsVertical;    //true or false
    pFontColor;
    
    pEnablePatronImage;         //true to enable
    pPatronImageBorder;         //true to enable
    pBarcode;                   //true to enable
    pBarcodeShowValue;          //true to show the value

    pPrimaryField;              //single ColoredPassField object
    pFrontSecondaryFields;      //array of pass field object with keys: label, dynamicDataType, and fixedText
    pBackFields;                //array of pass field object with keys: label, dynamicDataType, and fixedText



    constructor(json) {
        if (json) { 
            this.name = json.name;
            this.version = json.version;

            this.extendedName = json.extendedName;
            this.logoImage = json.logoImage;
            
            this.enableElectronicPasses = json.enableElectronicPasses;
            this.enablePhysicalPasses = json.enablePhysicalPasses;
            this.bulkMail = json.bulkMail;
            this.bulkMailAddress = json.bulkMailAddress;
            this.lastBulkMailDate = json.lastBulkMailDate;  // leave this so post back to server won't be affected 
            this.specialHandling = json.specialHandling;

            this.ePassNeverExpires = json.ePassNeverExpires;
            this.eFontColor = json.eFontColor;
            this.eBackgroundColor = json.eBackgroundColor;
            this.eEnablePatronImage = json.eEnablePatronImage;
            this.eSecondaryFields = json.eSecondaryFields;          //Limit 3
            this.eAuxiliaryFields = json.eAuxiliaryFields;          //Limit 3
            this.eBackFields = json.eBackFields;                    //Limit 4

            this.eBarcode = json.eBarcode;
            this.eBarcodeShowValue = json.eBarcodeShowValue;

            this.pOrientationIsVertical = json.pOrientationIsVertical;
            this.pTitleFont = json.pTitleFont;
            this.pTitleFontSize = json.pTitleFontSize;
            this.pFontColor = json.pFontColor;
            this.pEnablePatronImage = json.pEnablePatronImage;
            this.pPatronImageBorder = json.pPatronImageBorder;
            this.pPrimaryField = json.pPrimaryField;
            this.pSecondaryFields = json.pSecondaryFields;
            this.pBackFields = json.pBackFields;
            this.pBarcode = json.pBarcode;
            this.pBarcodeShowValue = json.pBarcodeShowValue;
        }
    }
    

    getLastBulkMailDate() {
        if (this.lastBulkMailDate)
            return DateUtils.parseJsonDate(this.lastBulkMailDate, true); //convert from UTC
        else
            return null;
    }
    
    
    
    static createNew(name) {
        
        const c = new Community();
        c.name = name;
        c.enableElectronicPasses = false;
        c.enablePhysicalPasses = false;
        c.bulkMail = false;
        c.bulkMailAddress = "";
        c.lastBulkMailDate = null;
        c.specialHandling = "";
        
        c.eFontColor = '#000000';
        c.eBackgroundColor = '#ffffff';

        c.ePassNeverExpires = false;
        c.eEnablePatronImage = true;
        c.pPatronImageBorder = false;
        c.eSecondaryFields = [];
        c.eAuxiliaryFields = [];
        c.eBackFields = [];
        c.eBarcode = true;
        c.eBarcodeShowValue = false;

        c.pOrientationIsVertical = true;
        c.pTitleFont = "Arial";
        c.pTitleFontSize = 0; //normal
        c.pFontColor = '#000000';
        c.pEnablePatronImage = true;
        c.pPrimaryField = null;
        c.pSecondaryFields = [];
        c.pBackFields = [];
        c.pBarcode = true;
        c.pBarcodeShowValue = false;

        return c;
    }
    


   
}
import React, { useState, useRef, useEffect, useReducer }  from 'react';

import { Typography, Tooltip, IconButton } from '@material-ui/core'
import ReactCardFlip from 'react-card-flip';

import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

import { ThemeColors } from '../Theme'

import { DynamicDataType, sampleData, setSampleData, evaluate, sampleDataHasError } from './PassField';
import { ManageTextField, DateUtils, ColorUtils } from 'react-frontend-utils';


const TEST_NAME = "Jane Doe";
const MAX_LENGTH_SECONDARY_PORTRAIT = 18;
const MAX_LENGTH_SECONDARY_LANDSCAPE = 26;

//ISO/IEC 7810 standard card dimensions, mm
const WIDTH = 85.60;
const HEIGHT =  53.98;
const CARD_ASPECT_RATIO = WIDTH/HEIGHT;  //width to height

const OUTER_PADDING = 0.06;  

//Height of the header, as a ratio to the full card height
const HEADER_PORTRAIT_HEIGHT_RATIO = 0.15;   
const HEADER_LANDSCAPE_HEIGHT_RATIO = 0.18;   

//Height of the barcode, as a ratio to the full card height
const BARCODE_PORTRAIT_HEIGHT_RATIO = 0.15;
const BARCODE_LANDSCAPE_HEIGHT_RATIO = 0.22;

/**
 * Preview component for an Physical Pass, front and back sides.  Provides an animation to flip between front and back sides.
 * Styling and relative dimensions based on the zeld designer template and ISO standard card dimensions.
 * 
 * This is display only - no changes to the pass are made in this component
 * 
 * Caller must pass props:
 *  
 *  passData: object that contains all the fields of the Community object starting with "p".  Can be the Community object itself.
 *  previewWidth: desired render width, the height is scaled based on the aspect ratio of the pass
 *  maxFrontFields: maximum number of front fields allowed
 * portraitBarcodeOnBack: if the barcode in portrait mode should be put on the back of the card
 */

export function PPassPreview(props) {

    const [isFlipped, setFlipped] = useState(false); //front is not flipped, back is flipped
    const [testName, setTestName] = useState(TEST_NAME);

    // -- Quick and dirty way to refresh when sample data is updated
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const data = props.passData;
    let width = props.previewWidth;

    let fontScale = props.previewWidth / 550;    //nominally, a scale of 1 is represented when the width is 300 pixels, otherwise scale up or down

    let height = props.previewWidth / CARD_ASPECT_RATIO;   //height scales with width 

    if (data.pOrientationIsVertical) {  //swap if portrait 
        width = height;
        height = props.previewWidth;
    }

    const reducedFrontFields = data.pSecondaryFields.slice(0, props.maxFrontFields);  //limit to the amount allowed


    //Outer pass style for front and back
    const passStyle = {
        height: height, 
        width: width, 
        border: '1px solid gray',
        borderRadius: 0.05 * props.previewWidth,       //scale as size grows
        backgroundColor: 'white',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19)'
    }


    //Padding around edge
    const contentStyle = {
        padding: OUTER_PADDING * (height > width ? width : height)    //take smaller
    }

    //The total height, less the outer padding, plus 1 additional padding to seperate header from landscape/portrait container
    const heightLessPadding = height - 3*contentStyle.padding;
    const widthLessPadding = width - 2*contentStyle.padding;

    const headerHeightRatio = data.pOrientationIsVertical ? HEADER_PORTRAIT_HEIGHT_RATIO : HEADER_LANDSCAPE_HEIGHT_RATIO;

    const landscapePortraitHeight = heightLessPadding * (1 - headerHeightRatio);  //remaining space for container

    //Content of the header is at the top of the front of pass
    const headerContainerStyle = {
        height: heightLessPadding * headerHeightRatio, 
        width: '100%', 
        display: 'flex',
        alignItems: 'center',
        gap: '2%',
        marginBottom: contentStyle.padding,  //add the same padding to the bottom to seperate header
    }

    //Style for the section below the header, in landscape mode
    const landscapeContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        height: landscapePortraitHeight
    }

    //Style for the section below the header, in portrait mode
    const portraitContainerStyle = {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        height: landscapePortraitHeight
    }

    //Back container 
    const backContainerStyle = {
        ...contentStyle, 
        height: height - 2*contentStyle.padding, //all space for container
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between'
    }
    
    //Scaled text for values, where 1 is the scale of the Logo Title
    const valueText = (text, scale = 1, bold = false, align = 'left', color = 'black', italic = false) => {
        return (
            <div style={{color: color, fontWeight: bold ? 'bold' : 'normal', textAlign: align, fontSize: scale * 32 * fontScale, fontStyle: italic ? 'italic' : 'normal'}}>
                {text}
            </div>);
    }

    //Text for labels above a value
    const labelText = (text, align = 'left') => {
        return <div style={{fontWeight: 'bold', textAlign: align, fontSize: 20 * fontScale}}>{text}</div>
    }

    //Dynamically changing text component that resizes the font to fit the width
    const Name = (props) => {
        
        const [fontSize, setFontSize] = useState(props.scale * 32 * fontScale);  //set the initial font size to the desired scale
        const elementRef = useRef(null);

        useEffect(() => {
            if (elementRef.current.scrollWidth > props.containerWidth)
                setFontSize(fontSize * 0.95);  //reduce by 5% each iteration

          }, [props.containerWidth, fontSize]); //empty dependency array so it only runs once at render
        

        return (
            <div ref={elementRef} style={{display: 'block', fontSize: fontSize, whiteSpace: 'nowrap'}}>
                {props.text}
            </div>);
    }

    //The colored box sits just below the header
    const primaryField = (width = '100%') => {

        if (!data.pPrimaryField)  //not enabled, skip
            return null;

        let text = data.pPrimaryField.defaultValue;
        let color = data.pPrimaryField.defaultColor;
        let logicErrored = false;

        for (let colorLogic of data.pPrimaryField.colorLogic) { //evaluate the color logic, if any

            const result = evaluate(colorLogic.dynamicDataType, colorLogic.operator, colorLogic.operand);
            if (result) {
                text = colorLogic.resultWhenTrue;
                color = colorLogic.colorWhenTrue;
                break;
            }
            if (result === null) {
                logicErrored = true;
            }
            
        }
  
        const fontColor = ColorUtils.fontColorForBackground(color);

        if (logicErrored)
            text = "Logic Error!";

        if (text)
            text = text.replace("{{year}}", DateUtils.currentYear());  //replace year with current year

        return (
            <div style={{border: logicErrored ? '2px solid red' : 'none'}}>
                <div style={{backgroundColor: logicErrored ? 'white' : color, width: width, padding: '2%', marginBottom: '2%'}}>
                    {valueText(text, 0.8, false, 'center', logicErrored ? 'red' : fontColor, logicErrored)}
                </div>
            </div>
        );
    }

    //Secondary fields with label to the left and text to the right
    const secondaryField = (field, index, centered = false, maxChars = Number.MAX_VALUE) => {

        let text = sampleData(field.dynamicDataType, field.fixedText);
        if (text && text.length > maxChars)
            text = text.slice(0, maxChars);

        return (
            <div key={index} style={{width: '100%', display: 'flex', justifyContent: centered ? 'center' : 'flex-start', alignItems: 'flex-end', gap: '5%'}}>
                {field.label ? labelText(field.label) : null}
                {valueText(text, 0.6)}
            </div>
        );
    }

    //Secondary fields with label above and text below
    const stackedSecondaryField = (field, index, spacing) => {
        return (
            <div key={index} style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '2%', marginBottom: spacing}}>
                {field.label ? labelText(field.label) : null}
                {valueText(sampleData(field.dynamicDataType, field.fixedText), 0.6)}
            </div>
        );
    }

    const patronImage = (width) => {
        if (data.pEnablePatronImage)
            return <img src="samplePatron.jpg" alt="patronImage" width={width} style={{border: data.pPatronImageBorder ? '2px solid gray' : 'none'}}/>;
        else
            return null;
    }

    const barcode = (width) => {
        return data.pBarcode ? 
            <div style={{marginTop: '2%'}}>
                <img src="sampleCode128Barcode.jpg" alt="barcodeImage" style={{width: width ? width : widthLessPadding, height: height * (data.pOrientationIsVertical ? BARCODE_PORTRAIT_HEIGHT_RATIO : BARCODE_LANDSCAPE_HEIGHT_RATIO)}}/>
                {data.pBarcodeShowValue ? valueText("123456", 0.7, false, 'center', 'black') : null}
            </div>
            : null;
    }


    //Similar to stacked secondary fields, but with margin added
    const backField = (field, index) => {

        return (
            <div key={index} style={{width: '100%', marginBottom: data.pOrientationIsVertical ? '10%' : '5%'}}>
                {field.label ? labelText(field.label) : null}
                {valueText(sampleData(field.dynamicDataType, field.fixedText), 0.6, false, 'left')}
            </div>
        );
    }

    const titleFontScale = 1 + (data.pTitleFontSize * 0.2);

    //Render editable fields for dynamic data types
    const renderSampleDataField = (type, index) => {

        if (type.sampleData == null) // don't render if we don't have default sample data (ignore the FIXED enum)
            return null;

        const errored = sampleDataHasError(type);

        return (
            <div key={index} style={{marginTop: 20, border: errored ? '1px solid red' : 'none', padding: 3, paddingTop: 7}}>
                <ManageTextField label={"Sample " + type.label + (type.format ? " (" + type.format + ")" : "")}                              
                        json={type.enumName} 
                        tooltip={"Enter Sample " + type.label + " to render on card"} 
                        initialValue={type.sampleData}
                        autoAccept={true}
                        onFieldChange={(fieldName, userValue) => {
                                setSampleData(type.enumName, userValue);
                                forceUpdate();
                                props.refreshEpass();
                            }}/>             
            </div>
        );    
    }
 
    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10}}>
                <Typography variant='h6' align='center' style={{color: 'gray', fontStyle: 'italic', marginRight: 10}}>{"Pass Preview: " + (isFlipped ? "Back" : "Front")}</Typography>
                <Tooltip title={"Flip to view " + (isFlipped ? "front" : "back") + " of pass"}>
                    <IconButton onClick={() => setFlipped(!isFlipped)}>
                        <CompareArrowsIcon fontSize='large' style={{color: ThemeColors.appBarBackground}}/>
                    </IconButton>
                </Tooltip>
            </div>

            <ReactCardFlip isFlipped={isFlipped}>

                {/*--------------------------- FRONT OF PASS ------------------------------*/}                 
                <div style={passStyle}> 
                    <div style={contentStyle}>

                        <div style={headerContainerStyle}>  {/* ---- HEADER ------ */}
                            {data.logoImage ?
                                <img src={data.logoImage} alt="logo" width={headerContainerStyle.height} height={headerContainerStyle.height}/>
                                : null
                            }
                            <div style={{width: '100%', color: data.pFontColor, textAlign: data.logoImage ? 'left' : 'center', 
                                        fontWeight: 'bold', fontFamily: data.pTitleFont,
                                        fontSize: titleFontScale * 32 * fontScale}}>
                                {data.extendedName}
                            </div>
                        </div>

                
                        {data.pOrientationIsVertical ? //------------------ PORTRAIT --------------
                            <div style={portraitContainerStyle}>
                                {primaryField('100%')}
                                {patronImage('72%')}
                                <Name text={testName} scale={1.4} containerWidth={widthLessPadding}/>
                                
                                {data.pSecondaryFields.length > 0 || data.pEnablePatronImage || data.pBarcode ? null : <div style={{height: 1}}/>  /*empty space if needed*/}                          
                                
                                {data.pEnablePatronImage ?  //With image, use a regular secondary, otherwise stack them               
                                    reducedFrontFields.map((field, index) => secondaryField(field, index, true, MAX_LENGTH_SECONDARY_PORTRAIT))
                                    :
                                    reducedFrontFields.map((field, index) => stackedSecondaryField(field, index))
                                }
                                {props.portraitBarcodeOnBack ? null : barcode()}
                            </div>

                            :  //------------------ LANDSCAPE ----------------

                            <div style={landscapeContainerStyle}>

                                {/* ------ LEFT SECTION ------ */}
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '65%'}}>  
                                    {primaryField('96%')}
                                    <Name text={testName} scale={1.4} containerWidth={widthLessPadding * .65}/>
                                    {(data.pSecondaryFields.length > 0 && data.pEnablePatronImage) || data.pBarcode ? null : <div style={{height: 1}}/>  /*empty space if needed*/}                                
                                    {data.pEnablePatronImage ? (
                                            data.pBarcode ? reducedFrontFields.map((field, index) => secondaryField(field, index, false, MAX_LENGTH_SECONDARY_LANDSCAPE)) :
                                                            reducedFrontFields.map((field, index) => stackedSecondaryField(field, index))
                                        ) : null 
                                    }
                                    {barcode(widthLessPadding * .65)}
                                </div>

                                {/* ------ RIGHT SECTION ------ */}
                                {data.pEnablePatronImage ?
                                    <div style={{display: 'flex', alignItems: 'flex-start', width: '30%'}}>
                                        {patronImage('100%')}
                                    </div>
                                    : 
                                    <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                                        {reducedFrontFields.map((field, index) => stackedSecondaryField(field, index, '15%'))}
                                    </div>
                                }
                            </div>
                        }

                    </div>
                </div>

                {/*--------------------------- BACK OF PASS ------------------------------*/}
                <div style={passStyle}> 
                    <div style={backContainerStyle}>
                        <div style={{height: '100%'}}>
                            {data.pBackFields.map((field, index) => backField(field, index))}
                        </div>
                        {data.pBackFields.length > 0 ? null : <div style={{height: 1}}/> /*empty space if needed*/}
                        {props.portraitBarcodeOnBack ? barcode() : null}
                    </div>
                </div>


            </ReactCardFlip>

            <div style={{marginTop: 60}}>
                <ManageTextField label="Sample Patron Name"                              
                                json="TestName"  
                                tooltip="Enter a sample patron name. The size of the name on the card adjusts dynamically to fit"
                                initialValue={testName}
                                autoAccept={true}
                                onFieldChange={(fieldName, userValue) => {
                                        setTestName(userValue);
                                    }}/>                

                {Object.values(DynamicDataType).map((type, index) => renderSampleDataField(type, index))}
            </div>

        </div>
    );

}


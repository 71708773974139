
import { Job } from './Job'

export class PagedJobsList {
    
 
    pageNo;
    
    index;
    isLastPage;
    jobs;
    stateCounts;            //converted from backend to an array of objects {state: a State enum object, and count: a count of that state}
    
    batchPrintableCount = 0;

    agsBacklog;
    oldestBacklog;
    agsBulkCount;

    //Construct from the backend Json Response
    constructor(json) {
        if (json) {
            this.pageNo = json.pageNo; 
            this.index = json.index;
            this.isLastPage = json.isLast;
            if (json.jobs) {
                this.jobs = json.jobs.map(job => new Job(job));
                for (const job of this.jobs) {
                    if (job.batchPrintable())
                        this.batchPrintableCount++;
                }
            }
            else
                this.jobs = [];
            
           if (json.stateCounts)
                this.stateCounts = json.stateCounts.map(stateCount => { return {state: Job.stateFromString(stateCount.state), count: stateCount.count}; });
           else
                this.stateCounts = [];
            
            this.agsBacklog = json.agsBacklog;
            this.oldestBacklog = json.oldestBacklog;
            this.agsBulkCount = json.agsBulkCount;

        }  
    } 
    
    isFirst() {
        return this.pageNo === 0;
    }
    
    isLast() {
        return this.isLastPage;
    }
    
}
import React from 'react';
import { Typography, Grid, Button, Container } from '@material-ui/core'

import { ThemeColors } from '../Theme'

//The welcome page displays when there is no logged in user. It is informational in nature only.     
export function WelcomePage(props) {

    
    return (
            
        <Container style={{paddingTop: 100}}>
            <Typography variant="h4" style={{textAlign: 'center', paddingBottom: 10}}>Welcome to the Pass Service Web Portal</Typography>
            <Typography variant="h5" style={{textAlign: 'center', paddingBottom: 10}}>Please login to manage pass printing and electronic passes</Typography>
             <Grid container justifyContent="center">
                <Button onClick={props.onLogin} variant='contained' style={{color: 'white', width: 300, backgroundColor: ThemeColors.appBarBackground}} >Login to the Portal</Button>
            </Grid>
           
        </Container>
    
    );
    
    
}




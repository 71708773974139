import { Tooltip } from '@material-ui/core'

import { DateUtils } from 'react-frontend-utils'
import { JournalEntry } from './JournalEntry'

import { ThemeColors } from '../Theme'
import { Global } from './Global'


export class Job {

    static State = {

        QUEUED:      {enumName: "QUEUED", bit: 0, label: "Queued", backgroundColor: ThemeColors.queuedBlue},  
        CLAIMED:     {enumName: "CLAIMED", bit: 1, label: "Claimed", backgroundColor: ThemeColors.claimedPurple},
        PRINTED:     {enumName: "PRINTED", bit: 2, label: "Printed", backgroundColor: ThemeColors.processedGreen},
        SENT:        {enumName: "SENT", bit: 3, label: "Sent", backgroundColor: ThemeColors.processedGreen},
        ERRORED:     {enumName: "ERRORED", bit: 4, label: "Errored", backgroundColor: ThemeColors.erroredRed},
        ARCHIVED:    {enumName: "ARCHIVED", bit: 5, label: "Archived", backgroundColor: ThemeColors.archivedGray}          
    };
          
    id;
    type;               //Class type, for Jackson
    
    submitDate;         //Converted to local
    source;
    kind;

    group;      
    stateEnum;
    claimedBy;
    claimerName;
    claimedAt;          //Converted to local
    
    journal;

    
    passCount;

    emailDeliveryAddress;
    mailingAddress;                //may be seperated by newlines

    membershipID;
    specialHandling;


    suspectMailingAddress;          //true when a printed/mailed job contains an invalid or suspect mailing address
    missingBarcodes;   
    deliveryClaim; 

    bulkMail;

    constructor(json, forPatron = false) {
        if (json) {
            
            this.id = json.id;
            this.type = json.type;
            if (json.submitDate) {
                this.submitDate = DateUtils.parseJsonDate(json.submitDate, true); //convert from UTC
            }
         
            this.kind = json.kind;
            this.group = json.group;
            if (json.state) {
                this.stateEnum = Job.stateFromString(json.state);    
            }
            
            this.source = json.source;
            this.claimedBy = json.claimedBy;
            this.claimerName = json.claimerName;
            if (json.claimedAt) {
                this.claimedAt = DateUtils.parseJsonDate(json.claimedAt, true); //convert from UTC
            }
            else
                this.claimedAt = null;
            
            if (json.journal) {
                this.journal = json.journal.map(entry => new JournalEntry(entry));        
            }
            else
                this.journal = []; 

            this.passCount = json.passCount;
            this.emailDeliveryAddress = json.emailDeliveryAddress;
            this.mailingAddress = json.mailingAddress;

            this.membershipID = json.membershipID;
            this.specialHandling = json.specialHandling;
           
            this.suspectMailingAddress = json.suspectMailingAddress;
            this.missingBarcodes = json.missingBarcodes;
            this.deliveryClaim = json.deliveryClaim;
            this.bulkMail = json.bulkMail;
        }
    }

    static stateFromString(str) {
        for (let index in Job.State) {
            const state = Job.State[index];

            if (state.enumName === str) {
                return state;
            }
        }  
        return null;
    }
    
    deliverTo() {
        if (this.kind === "CARD" || this.kind === "BULK_REQUEST")
            return this.mailingAddress;
        else
            return this.emailDeliveryAddress;
    }


    claimable() {

        if (this.kind !== "CARD" && this.kind !== "BULK_REQUEST")  //Only cards or bulk request can be claimed
            return false;

        switch (this.stateEnum) {
            case Job.State.QUEUED:
                return true;
               
            default:
                return false;
        }
    }

    requeueable() {
        switch (this.stateEnum) {
            case Job.State.QUEUED:
            case Job.State.CLAIMED:
            case Job.State.ARCHIVED:
                return false;
               
            default:
                return true;
        }
    }

    // must be requeable, a card job, and not have a delivery claim already
    canTriggerDeliveryClaim() {
        return this.requeueable() && this.kind === "CARD" && !this.deliveryClaim;
    }

    downloadable() {
        return this.kind !== "EPASS" && this.kind !== "BULK_REQUEST" && this.stateEnum !== Job.State.ARCHIVED;  //Epasses cannot be downloaded, Archived passes have no data
    }

    
    claimedByUser() {
        return this.stateEnum === Job.State.CLAIMED && this.claimedBy === Global.user.id;
    }
    
    batchPrintable() {
        return this.claimedByUser() &&  this.kind === "CARD";
    }

    
    //Conver to JSON for posting, removes items that are not allowed by backend
    toJsonForPost() {
        
        this.id = null; 
        this.submitDate = null; //will not be used by backend      
        this.stateEnum = null;  //not used by backend
        this.journal = null; 
                
        return JSON.stringify(this);
    }

    
    alertString() {
        let alert = "";
        if (this.suspectMailingAddress)
            alert += "This Job has a suspect mailing address. ";
        if (this.deliveryClaim)
            alert += "This Job has a mail delivery claim. ";
        if (this.missingBarcodes === 1)
            alert += "1 patron is missing a barcode.";
        else if (this.missingBarcodes > 0)
            alert += this.missingBarcodes + " patrons are missing barcodes.";

        return alert;
    }


    //Render the Journal Entries
    renderJournal() {
        
        const stateChangeActivity = (entry) => {
            if (entry.activity.startsWith("Released to ")) {
                const stateReleased = Job.stateFromString(entry.activity.substring("Released to ".length));
            
                return <div style={{display: 'flex'}}>
                            Released to
                            <div style={{marginLeft: 5, paddingLeft: 3, paddingRight: 5, backgroundColor: stateReleased.backgroundColor, color: 'white', textTransform: 'uppercase'}}>
                                {stateReleased.label}
                            </div>
                        </div>;
            }
            else
                return <div>{entry.activity}</div>;
        };

        return (<div style={{marginLeft: 40, marginRight: 40}}>
                    {this.journal.map((entry, index) => {
                        return (
                            <div key={index} style={{backgroundColor: ThemeColors.notifyBackground, marginBottom: 2, padding: 5, borderRadius: 3}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 200}}>{DateUtils.timeFormat(entry.date)}</div>
                                    <div style={{width: 200}}>{entry.author}</div>
                                    {stateChangeActivity(entry)}
                                </div>
                                <div style={{fontStyle: 'italic', fontSize: 12, marginLeft: 400}}>{entry.note ? "Notes: " + entry.note : null}</div>
                            </div>
                        );
                    })}
                </div>);
    }


    static badgeStyle = {
        alert: {
            alignContent: 'center',
            width: 14,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: '20%', 
            color: 'white', 
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 12
        },
        bulk: {
            alignContent: 'center',
            width: 14,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: '50%', 
            color: 'black', 
            textAlign: 'center',
            fontSize: 12
        }
    };


    renderBadges(justify = 'left') {
        const alert = this.alertString();
        return <div style={{display: 'flex', justifyContent: justify, gap: 3}}>
                    <Tooltip title={alert}>
                        <div style={{...Job.badgeStyle.alert, backgroundColor: alert ? 'red' : 'white'}}>
                            {alert ? "!" : ""}
                        </div>
                    </Tooltip>
                    <Tooltip title={this.bulkMail ? "This Job belongs to a bulk-mailing community" : ""}>
                        <div style={{...Job.badgeStyle.bulk, backgroundColor: this.bulkMail ? 'cyan' : 'white'}}>
                            {this.bulkMail ? "B" : ""}
                        </div>
                    </Tooltip>
                </div>;
    }
    
}
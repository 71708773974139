import React, { useState } from 'react';


import { IconButton, Tooltip, Typography, Popover, Container } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';

import { ThemeColors } from '../Theme';

import { ImageCropper, ImageUtils } from 'react-frontend-utils';


/**
 * Component to render a logo selector for Community.  Provides a file upload / clipboard copy / edit / delete and image preview capability
 * Aspect ratio is 1:1
 * 
 * Caller should pass the following props:
 * 
 *  previewWidth:  desired width (and height) of the logo preview
 *  image:  initially the image to display
 *  onError: callback function that takes an error string when file-loading fails
 *  onChange: callbackf function when the image changes.  Argument is the new image (base64 string) or null, if deleted
 * 
 */


const maxImageDimension = 100;

export function LogoSelector(props) {
        
    const width = props.previewWidth;
    const initialImage = props.image;

    const [displayedImage, setDisplayedImage] = useState(initialImage);   //the image displayed
    const [imageToCrop, setImageToCrop] = useState(null);                 //the image to crop, initially none
    
    const [showCropper, setShowCropper] = useState(false);                
    
    //Callback when File is selected, read the file and set it as the image to crop
    const cropFile = (file) => {
        
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
 
        if (!acceptedImageTypes.includes(file['type'])) {
            props.onError("File is not a valid image type");
            setImageToCrop(null); 
            return;
        }

        ImageUtils.readImageFile(file, (dataURL) => {
            setImageToCrop(dataURL);     //we read the file, set the image to crop  
            setShowCropper(true);        //turn on the cropper mode
        });
    
    };

    //Callback when a file is selected
    const fileSelected = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log("Selected image file " + file.name);
            cropFile(file);
        }
        event.target.value = null;  //allow picking of the same value again

    };
   
    //Callback from ImageCropper when Image has been cropped
    const imageCropped = (image) => {
        if (image) {
            
            let width = parseInt(image.width);
            let height = parseInt(image.height);
            
            //Find the largest dimension and limit it
            height = height > maxImageDimension ? maxImageDimension : height;
            width = height;
          
            let imageB64 = ImageUtils.scaleImage(image, width, height, "image/png");  //to png, in case we want to crop later - preserve quality
                 
            setDisplayedImage(imageB64);  //display the new scaled image
            setImageToCrop(null);
            
            //For Application, convert to jpeg to make smaller
            let jpegImage = ImageUtils.scaleImage(image, width, height, "image/jpeg");
                        
            if (props.onChange)
                props.onChange(jpegImage);
                        
        }
        setShowCropper(false);
    };
    
    const crop = () => {
        setImageToCrop(displayedImage);     //we read the file, set the image to crop  
        setShowCropper(true);        //turn on the cropper mode
    };
    
    const copyFromClipboard = () => {
        ImageUtils.copyImageFromClipboard((copiedImage) => {
            setImageToCrop(copiedImage);     
            setShowCropper(true);        //turn on the cropper mode
        });
    };
    
    const removeImage = () => {
        setDisplayedImage(null);  
        if (props.onChange)
            props.onChange(null);
    };
    
    
    
    const uniqueFileInputID = "fileSelection";
    
        
    return (
        <div>
    
            <Popover open={showCropper} 
                        anchorReference="anchorPosition"
                        anchorPosition={{left: window.innerWidth/2, top: 0}}
                        transformOrigin={{vertical: 'center', horizontal: 'center'}} >
                        
                <Container style={{padding: 20, width: window.innerWidth * 0.98}}>         
                    <ImageCropper image={imageToCrop} 
                                  aspectRatio={1} 
                                  onDone={imageCropped} 
                                  cancelIconColor={ThemeColors.darkRed}/> 
                    
                </Container>
                        
            </Popover>
    
 
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
            
                {displayedImage ? 
                    <img style={{border: '1px solid gray'}} src={displayedImage} alt="Logo" width={width} height={width}/>
                    : 
                    <div style={{width: width, height: width, backgroundColor: 'lightGray'}}>None</div>
                }

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <input accept="image/*" id={uniqueFileInputID} style={{display: 'none'}} type="file" onChange={fileSelected}/>                                      
                    <Tooltip title="Load from Image File">
                        <label htmlFor={uniqueFileInputID}>
                            <IconButton component="span">
                                <DescriptionIcon fontSize="medium" style={{color: '#87854a'}}/>
                            </IconButton>
                        </label>
                    </Tooltip>
                    <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: 12, marginTop: 4}}>File Upload</Typography>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Tooltip title="Paste from Clipboard">
                        <IconButton onClick={copyFromClipboard} >
                            <AssignmentReturnedIcon fontSize="medium"/>
                        </IconButton>
                    </Tooltip>
                    <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: 12}}>From Clipboard</Typography>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Tooltip title="Edit/Crop">
                        <IconButton disabled={!displayedImage} onClick={crop} >
                            <CropRotateIcon fontSize="medium" style={{color: displayedImage ? 'green' : 'lightGray'}} />
                        </IconButton>
                    </Tooltip>
                    <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: 12}}>Edit/Crop</Typography>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Tooltip title="Remove Image">
                        <IconButton disabled={!displayedImage} onClick={removeImage} >
                            <ClearOutlinedIcon fontSize="medium" style={{color: displayedImage ? ThemeColors.darkRed : 'lightGray'}}/>
                        </IconButton>
                    </Tooltip>
                    <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: 12}}>Clear</Typography>
                </div>
            </div>
                 
        </div>
    );

} 

import React from 'react';
import { createTheme } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon';


export const ThemeColors = {
    
    appBarBackground: '#3F51B5',  //primary color for Material-UI
    selectedColor: '#AAAAFF',
    selectedColorHover: '#8888FF',

    queuedBlue: '#5d8fd4',
    processedGreen: '#45bf4b',
    erroredRed: '#EC2424',
    claimedPurple: '#b545bf',
    batchGreen: '#429274',
    bulkMailTan: '#85713c',
    archivedGray: '#9e978d',
            
    viewBlue: '#70deff',
    settingsOlive: '#767d63',

    darkRed: '#AB352C',
    lightBlue: '#4FC3E3',
    mediumGray: '#444444',
    darkGray: '#222222',
    lighterBlue: '#24A6EB',

    memberRed: '#EC2424',  //darker redish for charts
    guestBlue: '#3C3CEC',  
    pink: '#CD24EB', 
    membershipsGreen: '#46D846',
    membersBlue: '#0A0AF0',
    patronGreen: '#03a133',
    validGreen: '#9effa3',

    addColor: '#edaa0c',
    keyColor: '#AB8C00',
    calendarColor: '#a39468',
    clockColor: '#91a6c7',
    mailColor: '#dbb286',
    transactionColor: '#a8a632',
    cancelGray: '#737373',
    adjustPurple: '#a670b5',
    moneyGreen: '#157a58',
    tooltipLightGray: '#EEEEEE',
    redeemIndigo: '#785de3',
    userWarnStatus: '#ff8800',
    newUser: '#275e48',
    overdueOrange: '#f2a230',
    settledPurple: '#b545bf',
    settingsGray: 'a2b6ba',
    templateGray: '#F2F2F2',
    templateTab: '#D2D2D2',
    editGreen: '#6eff75',
    
    templateBackground: '#b0dae8',
    templateBorder: '#a1c7d4',   
    templateMagenta: '#f542f5',

    tableHeaderBackgroundColor: '#EEEEEE',
    tableAlternatingRowsGray: '#FAFAFA',
    tableHover: '#EEEEFF',
    
    lightTooltipHover: '#FFFFFF1A',
    notifyBackground: '#E6E4D5',
    containerColor: '#FAFAFA',
    applicantTan: '#F2EFD5',
    applicantDrag: '#F5EDA9',
    memberDragOver: '#8C97CF',
    
    errorRed: '#EC2424',
    processingYellow: '#ffeeb5',
    
    veryLightBlue: '#FCFCFF',
        
    signatureStrokeColor: '#0737b0',
    signaturePadColor: '#ebe9d3',
    
    pieChartColors: ['#4545ff', '#e03434', '#42db42', '#f7cd40', '#9449f5', '#f035e3', '#3febe8', '#eb873f', '#4c804b', '#ff9c9c', '#826c38', '#386782', '#808080',
                     '#85283d', '#9ca635', '#8c4d1d', '#cbb8ff', '#b1d190', '#c9c08f', '#363230']

    
    
};

export const ThemeIcons = {
    
    StopIcon: (props) => {
        return (<SvgIcon {...props}>
                <path fill="currentColor" d="M8.27,3L3,8.27V15.73L8.27,21H15.73L21,15.73V8.27L15.73,3M8.41,7L12,10.59L15.59,7L17,8.41L13.41,12L17,15.59L15.59,17L12,13.41L8.41,17L7,15.59L10.59,12L7,8.41" />
              </SvgIcon>);
    }     
 
};


export const BaseFonts = ["Arial", "Brush Script MT", "Candara", "Calibri", "Copperplate", "Courier New", "Didot", "Garamond", 
                          "Georgia", "Geneva", "Helvetica", "Optima", "Tahoma", "Times New Roman", "Verdana"];


export const GoogleFonts =  [
'Abril Fatface',
'Acme',
'Allura',
'Anton',
'Bree Serif',
'Charm',
'Cinzel',
'Comfortaa',
'Dancing Script',
'DM Serif Display',
'Gowun Batang',
'Graduate',
'Great Vibes',
'Imperial Script',
'Libre Baskerville',
'Lobster',
'Merriweather',
'Quando',
'Pacifico',
'Poiret One',
'Poppins',
'Prata',
'Saira',
'Special Elite',
'Stardos Stencil',
'Tangerine',
'Varela Round'];




const AGTheme = createTheme({
    
    palette: {
        light: '#4444FF'
    },
    styledTooltip: {
        backgroundColor: '#000099',
        color: 'white',
        maxWidth: 500,
        fontSize: 12,
        border: '2px solid #ffffff'
    },
    overrides: {
        MuiContainer: {
            root: {
              padding: '10px'
            }
        },
        MuiListItem: {
            root: {
              "&$selected": {backgroundColor: ThemeColors.selectedColor, "&:hover": {backgroundColor: ThemeColors.selectedColorHover}}
            }
        }
    }
    
});


export default AGTheme



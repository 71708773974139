import React, { useState } from 'react';

import { Tooltip, Typography, TextField, IconButton } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';

import { DynamicDataType, getDynamicDataTypeFromLabel, getDynamicDataTypeFromEnum } from './PassField';
import { ManageTextField, ColorPicker } from 'react-frontend-utils';

import { ThemeColors } from '../Theme'


const Operator = {

    EQUALS:             {enumName: "EQUALS", label: "="},  
    GREATER_THAN:       {enumName: "GREATER_THAN", label: ">"},  
    LESS_THAN:          {enumName: "LESS_THAN", label: "<"},
    CONTAINS:           {enumName: "CONTAINS", label: "contains"},
    STARTS_WITH:        {enumName: "STARTS_WITH", label: "starts-with"},
    ENDS_WITH:          {enumName: "ENDS_WITH", label: "ends-with"}    
};



//Get the Operator object from the supplied enum (undefined if not found)
function getOperatorFromEnum(str) {

    for (const op of Object.values(Operator)) {
        if (str === op.enumName)
            return op;
    }
}


//Get the Operator object from the supplied String (undefined if not found)
function getOperatorFromLabel(str) {

    for (const op of Object.values(Operator)) {
        if (str === op.label)
            return op;
    }
}


export function ColorLogic(props) {

    const logic = props.logic;

    const initialType = getDynamicDataTypeFromEnum(logic.dynamicDataType);

    const [dynType, setDynType] = useState(initialType ? initialType.label : null); 
    const [dynTypeVal, setDynTypeVal] = useState(initialType ? initialType.label : ""); 

    //Set the enum based on the label
    const selectType = (event, newTypeLabel) => {
        if (!newTypeLabel)
            return;
        setDynType(newTypeLabel);
        const type = getDynamicDataTypeFromLabel(newTypeLabel);
        props.logic.dynamicDataType = type.enumName;
        props.onChange();
    }

    //Get all the dynamic type labels for the Autocomplete, removing the FIXED type
    const dynTypes = Object.values(DynamicDataType).filter(type => type.enumName !== DynamicDataType.FIXED.enumName).map(type => type.label)

    const initialOperator = getOperatorFromEnum(logic.operator);

    const [operator, setOperator] = useState(initialOperator ? initialOperator.label : null); 
    const [operatorVal, setOperatorVal] = useState(initialOperator ? initialOperator.label : ""); 

    //Set the enum based on the label
    const selectOperator = (event, newOperatorLabel) => {
        if (!newOperatorLabel)
            return;
        setOperator(newOperatorLabel);
        const op = getOperatorFromLabel(newOperatorLabel);
        props.logic.operator = op.enumName;
        props.onChange();
    }

    //Get all the operator labels for the Autocomplete
    const operators = Object.values(Operator).map(op => op.label)

    //Get the label appropriate for the Operand
    let labelForOperand;
    switch (initialType) {
        case DynamicDataType.ADDRESS:
        case DynamicDataType.MEMBERSHIP_ID:
        case DynamicDataType.MEMBERSHIP_TYPE:
        case DynamicDataType.RELATION:
        case DynamicDataType.EMERGENCY_CONTACT:
            labelForOperand = "Text";
            break;
        case DynamicDataType.AGE:
            labelForOperand = "Numeric Age";
            break;
        case DynamicDataType.BIRTHDATE:
        case DynamicDataType.EXPIRATON_DATE:
            labelForOperand = "Date (YYYY-MM-DD)"
            break;
        default:
            labelForOperand = "Unknown";
    }

    return (
        <div style={{marginTop: 10, marginBottom: 25}}>

            {props.index === 0 ? null : 
                <div style={{marginBottom: 25}}>
                    <Typography style={{fontSize: 16, color: ThemeColors.appBarBackground}} variant="button">ELSE</Typography>
                </div>
            }

            <div style={{display: 'flex', gap: 5, alignItems: 'center', marginBottom: 10}}>
                    <Typography style={{marginRight: 5, fontSize: 16, color: ThemeColors.appBarBackground}} variant="button">IF</Typography>  
                    <Autocomplete
                        style={{minWidth: 200}}
                        size='small'
                        disableClearable
                        value={dynType}
                        onChange={selectType}
                        inputValue={dynTypeVal}
                        onInputChange={(event, newValue) => { setDynTypeVal(newValue) }}
                        options={dynTypes}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Data Type" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />    
                    <Autocomplete
                        style={{minWidth: 150}}
                        size='small'
                        disableClearable
                        value={operator}
                        onChange={selectOperator}
                        inputValue={operatorVal}
                        onInputChange={(event, newValue) => { setOperatorVal(newValue) }}
                        options={operators}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Operator" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />    
                    <ManageTextField  
                                json="operand"  
                                label={labelForOperand}
                                initialValue={props.logic.operand}
                                autoAccept={true}
                                onFieldChange={(fieldName, userValue) => {
                                    props.logic.operand = userValue;
                                    props.onChange();
                                }}/>
                    <Tooltip title="Delete this logic">
                        <IconButton onClick={() => props.onDelete(props.index)}>
                            <DeleteIcon />
                        </IconButton>       
                    </Tooltip> 
            </div>
            <div style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                <Typography style={{marginRight: 5, fontSize: 16, color: ThemeColors.appBarBackground}} variant="button">THEN</Typography>  
                <ManageTextField label="Primary Field Text"                              
                                 json="result"  
                                 initialValue={props.logic.resultWhenTrue}
                                 autoAccept={true}
                                 tooltip="Text displayed when this logic evalutes to true. You may use the code {{year}} to substitute the current year printed."
                                 onFieldChange={(fieldName, userValue) => {
                                    props.logic.resultWhenTrue = userValue;
                                    props.onChange();
                                }}/>
                <Typography align='right' variant='body1' style={{marginLeft: 10}}>Background</Typography>      
                <ColorPicker initialColor={props.logic.colorWhenTrue} 
                            disableAlpha={true}
                            onChange={(color) => {props.logic.colorWhenTrue = color.hex; props.onChange(); }}/>
            </div>
        </div>
    );
}

import React, { useState }  from 'react';

import { Grid, Typography, Checkbox, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core'

import { renderFieldGroupEdit, renderPrimaryPhysicalGroupEdit } from './PassEdit';
import { ColorPicker, ManageTextField } from 'react-frontend-utils'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { DynamicDataType } from '../components/PassField';
import { PPassPreview } from '../components/PPassPreview';
import { BaseFonts, GoogleFonts, ThemeColors } from '../Theme';

/**
 * Designer page for Physical Passes.  The left side is the builder and the right side is the preview.
 * Caller must pass the following props:
 * 
 *  community:  the Community object whose pass fields are being edited
 *  windowWidth: the current window width, to dynamically update as the window changes
 *  onChange: a callback function taking no arguments, that is called when any part of the pass changes (should call forceUpdate to update the preview)
 * 
 */


export function PhysicalPassBuilder(props) {

    const style = {
        checkbox: {display: 'flex', alignItems: 'center', marginLeft: -10},
        fieldLabel: {display: 'flex', alignItems: 'center'},
        fieldGroup: {border: '1px solid lightGray', borderRadius: 5, padding: 10, marginTop: 20},
        roundedContainer: {
            border: '1px solid #CCCCCC', 
            borderRadius: '4px', 
            padding: 10,
        },
        containerLabel: {
            fontSize: 12, 
            color: 'gray', 
            marginLeft: 5
        }
    };

    const allFonts = [...GoogleFonts, ...BaseFonts];
    const titleFonts = allFonts.sort();

    const community = props.community;
    const [orientationIsVertical, setOrientationIsVertical] = useState(community.pOrientationIsVertical ? "Portrait" : "Landscape");
    const [pPrimaryField, setPPrimaryField] = useState(community.pPrimaryField); 
    const [pSecondaryFields, setPSecondaryFields] = useState(community.pSecondaryFields); 
    const [pBackFields, setPBackFields] = useState(community.pBackFields); 
    const [showBarcode, setShowBarcode] = useState(community.pBarcode); 

    const [titleFont, setTitleFont] = useState(community.pTitleFont ? community.pTitleFont : titleFonts[0]); 
    const [titleFontVal, setTitleFontVal] = useState(community.pTitleFont ? community.pTitleFont : ""); 

    const selectTitleFont = (event, newFont) => {
        if (!newFont)
            return;
        setTitleFont(newFont);
        community.pTitleFont = newFont;
        props.onChange();
    }


    const MAX_BACK_FIELDS = 4;

    let maxFrontFields = 1;  //with pictures, just 1 front field
    if (!community.pEnablePatronImage)
        maxFrontFields = 3;     //more without pictures
    else if (!community.pPrimaryField)
        maxFrontFields = 2;
    else if (!community.pOrientationIsVertical && !community.pBarcode)
        maxFrontFields = 2;
    
    const portraitBarcodeOnBack = community.pOrientationIsVertical && community.pEnablePatronImage;

    const reducedFrontFields = pSecondaryFields.slice(0, maxFrontFields);  //limit to the amount allowed

    const enablePrimaryField = (enable) => {
        if (!enable) {
            community.pPrimaryField = null;
            setPPrimaryField(null);
        }
        else {
            community.pPrimaryField = {colorLogic: [], defaultColor: ThemeColors.queuedBlue, defaultValue: "Primary Field Text"};
            setPPrimaryField(community.pPrimaryField);
        }
        props.onChange();
    }

    const addSecondaryField = () => {
        community.pSecondaryFields = [...community.pSecondaryFields, {label: "Your Label", dynamicDataType: DynamicDataType.MEMBERSHIP_ID.enumName}];
        setPSecondaryFields(community.pSecondaryFields);
        props.onChange();
    }

    const removeSecondaryField = () => {
        community.pSecondaryFields = community.pSecondaryFields.slice(0, -1);
        setPSecondaryFields(community.pSecondaryFields);
        props.onChange();
    }

    const addBackField = () => {
        community.pBackFields = [...community.pBackFields, {label: "Your Label", dynamicDataType: DynamicDataType.FIXED.enumName}];
        setPBackFields(community.pBackFields);
        props.onChange();
    }

    const removeBackField = () => {
        community.pBackFields = community.pBackFields.slice(0, -1);
        setPBackFields(community.pBackFields);
        props.onChange();
    }

    const changeFontSize = (event) => {
        community.pTitleFontSize = Number.parseInt(event.target.value);
        props.onChange();
    }

    const changeOrientation = (event) => {
        if (event.target.value === "Portrait")
            community.pOrientationIsVertical = true;
        else
            community.pOrientationIsVertical = false;

        setOrientationIsVertical(event.target.value);
    }

    //Render at 85% of the window when we are below "md" size, otherwise 45%
    let previewWidth = props.windowWidth < 960 ? props.windowWidth * 0.85 : props.windowWidth * 0.45;

    if (previewWidth > 550)  //limit max size
        previewWidth = 550;

    return (

        <Grid container spacing={2}>


            <Grid item xs={12} md={6}>
                
                <div style={style.checkbox}>
                    <Checkbox checked={community.bulkMail} color='primary' 
                                onChange={(event) => { community.bulkMail = event.target.checked; props.onChange(); }}/>                                  
                    <Typography variant='body1' align='left'>Bulk Mail Passes to Community (on Request)</Typography>                        
                </div> 

                <ManageTextField label="Bulk Mailing Address"
                                         initialValue={community.bulkMailAddress}
                                         style={{marginBottom: 25, marginTop: 10}}
                                         multiline={3}
                                         json="bulkMailAddress"  
                                         autoAccept={true}
                                         onFieldChange={(fieldName, value) => {community.bulkMailAddress = value; props.onChange(); }}/>

                <FormControl component="fieldset">
                    <RadioGroup row name="orientation" value={orientationIsVertical} onChange={changeOrientation}>
                        <FormControlLabel key={1} value="Portrait" control={<Radio color="primary" />} label={"Portrait"} />
                        <FormControlLabel key={2} value="Landscape" control={<Radio color="primary" />} label={"Landscape"} />
                    </RadioGroup>
                </FormControl>  

                <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>
                    <div style={style.checkbox}>
                        <Checkbox checked={community.pEnablePatronImage} color='primary' 
                                    onChange={(event) => { community.pEnablePatronImage = event.target.checked; props.onChange(); }}/>                                  
                        <Typography variant='body1' align='left'>Show Patron Image</Typography>                        
                    </div> 
                    <div style={{...style.checkbox, display: community.pEnablePatronImage ? 'flex' : 'none' }}>
                        <Checkbox checked={community.pPatronImageBorder} color='primary' disabled={!community.pEnablePatronImage} 
                                    onChange={(event) => { community.pPatronImageBorder = event.target.checked; props.onChange(); }}/>                                  
                        <Typography variant='body1' align='left'>Add Border</Typography>                        
                    </div> 
                </div>

                <div style={{display: 'flex', gap: 20, alignItems: 'center', marginBottom: 15}}>
                    <div style={style.checkbox}>
                        <Checkbox checked={showBarcode} color='primary' 
                                    onChange={(event) => { const checked = event.target.checked; 
                                                        setShowBarcode(checked);
                                                        community.pBarcode = checked;
                                                        props.onChange(); 
                                                        }}/>                                  
                        <Typography variant='body1' align='left'>Show Barcode</Typography>                        
                    </div> 
                    <div style={{...style.checkbox, display: community.pBarcode ? 'flex' : 'none' }}>
                        <Checkbox checked={community.pBarcodeShowValue} color='primary' disabled={!community.pBarcode} 
                                    onChange={(event) => { community.pBarcodeShowValue = event.target.checked; props.onChange(); }}/>                                  
                        <Typography variant='body1' align='left'>Show Barcode Value</Typography>                        
                    </div> 
                    {portraitBarcodeOnBack && community.pBarcode ? <Typography variant='body2' align='left' style={{color: 'gray', fontStyle: 'italic'}}>Barcode shows on back</Typography>  : null }
                </div>

                <Typography variant='body1' style={style.containerLabel}>Title</Typography>      
                <div style={{...style.roundedContainer, display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '3%'}}>
                    
                    <Autocomplete
                        style={{minWidth: 250}}
                        size='small'
                        disableClearable
                        value={titleFont}
                        onChange={selectTitleFont}
                        inputValue={titleFontVal}
                        onInputChange={(event, newValue) => { setTitleFontVal(newValue) }}
                        options={titleFonts}
                        renderOption={(option) => (
                            <div style={{fontFamily: option, fontSize: '1.2em'}}>{option}</div>
                          )}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Font" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />  

                    <ColorPicker initialColor={community.pFontColor} 
                                    disableAlpha={true}
                                    onChange={(color) => {community.pFontColor = color.hex; props.onChange(); }}/>

                    <FormControl component="fieldset">
                        <RadioGroup row name="fontsize" value={community.pTitleFontSize.toString()} onChange={changeFontSize}>
                            <FormControlLabel key={1} value="-1" control={<Radio color="primary" />} label={"Smaller"} />
                            <FormControlLabel key={2} value="0" control={<Radio color="primary" />} label={"Regular"} />
                            <FormControlLabel key={3} value="1" control={<Radio color="primary" />} label={"Large"} />
                            <FormControlLabel key={4} value="2" control={<Radio color="primary" />} label={"Largest"} />
                        </RadioGroup>
                    </FormControl>  

                </div>



                {renderPrimaryPhysicalGroupEdit("Primary", enablePrimaryField, pPrimaryField, props.onChange)}
                {renderFieldGroupEdit("Secondary", addSecondaryField, removeSecondaryField, reducedFrontFields, maxFrontFields, props.onChange)}
                {renderFieldGroupEdit("Back", addBackField, removeBackField, pBackFields, MAX_BACK_FIELDS, props.onChange)}

            </Grid>

            <Grid item xs={12} md={6}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <PPassPreview passData={community} previewWidth={previewWidth} maxFrontFields={maxFrontFields} portraitBarcodeOnBack={portraitBarcodeOnBack}
                                  refreshEpass={props.onChange}/>
                </div>
            </Grid>

        </Grid>
    );
}

import React from 'react';

import { Tooltip, Typography, IconButton, Paper, Button } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { PassField } from './PassField';
import { ColorLogic } from './ColorLogic';

import { ThemeColors } from '../Theme'
import { ManageTextField, ColorPicker } from 'react-frontend-utils';


const style = {
    fieldLabel: {display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10},
    fieldGroup: {border: '1px solid lightGray', borderRadius: 5, padding: 10, marginTop: 20}
};

//The block for each field group
export const renderFieldGroupEdit = (name, onAdd, onRemove, fieldArray, maxFields, onChange) => {

    return  <Paper style={style.fieldGroup}>                                      

                <div style={style.fieldLabel}>
                    <Typography style={{marginRight: 10, fontSize: 16, color: ThemeColors.appBarBackground}} variant="button">{name + " Fields"}</Typography>  
                    <Tooltip title={"Add a " + name + " field"}>
                        <IconButton edge="start" onClick={onAdd} disabled={fieldArray.length >= maxFields}>
                            <AddCircleOutlineIcon/>                         
                        </IconButton> 
                    </Tooltip>     
                    <Tooltip title={"Remove last " + name + " field"}>
                        <IconButton edge="start" onClick={onRemove} disabled={fieldArray.length <= 0}>
                            <RemoveCircleOutlineIcon/>                         
                        </IconButton>   
                    </Tooltip>
                    <Typography variant='body2' style={{color: 'gray', fontStyle: 'italic'}}>{"Maximum Fields: " + maxFields}</Typography>
                </div>

                {fieldArray.map((field, index) => <PassField key={index} field={field} onChange={onChange} fieldLabel={name + " Field " + (index + 1)}/>)}

            </Paper>;
}



//Block for physical primary field group
export const renderPrimaryPhysicalGroupEdit = (name, onEnable, field, onChange) => {
    
    const enabled = field != null; 

    const onAddColorLogic = () => {

        const randomKey = Math.floor(Math.random() * 2147483647);  //need a random key to preserve uniquness when adding/deleting from array

        field.colorLogic.push({key: randomKey, dynamicDataType: "MEMBERSHIP_TYPE", operator: "EQUALS", operand: "Test", resultWhenTrue: "Text when true", colorWhenTrue: '#ff0000'});
        onChange();
    }

    const onDeleteColorLogic = (index) => {
        field.colorLogic.splice(index, 1);
        onChange();
    }

    return  <Paper style={style.fieldGroup}>                                      

                <div style={style.fieldLabel}>
                    <Typography style={{marginRight: 10, fontSize: 16, color: ThemeColors.appBarBackground}} variant="button">{name + " Field"}</Typography>  
                    <Tooltip title={(enabled ? "Remove " : "Add ") + name + " field"}>
                        <IconButton edge="start" onClick={() => onEnable(!enabled)}>
                            {enabled ? <RemoveCircleOutlineIcon/> : <AddCircleOutlineIcon/>}                        
                        </IconButton> 
                    </Tooltip> 
                </div>

                {field ? 
                    <div>       
                        {field.colorLogic.map((logic, index) => <ColorLogic key={logic.key} index={index} logic={logic} onChange={onChange} onDelete={onDeleteColorLogic}/>)}
                        
                        <div style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                            {field.colorLogic.length > 0 ? <Typography style={{marginRight: 5, fontSize: 16, color: ThemeColors.appBarBackground}} variant="button">OTHERWISE</Typography> : null}
                            <ManageTextField label="Default Text"                              
                                            json="result"  
                                            initialValue={field.defaultValue}
                                            autoAccept={true}
                                            tooltip="Text displayed when none of the logic evalutes to true. You may use the code {{year}} to substitute the current year printed."
                                            onFieldChange={(fieldName, userValue) => {
                                                field.defaultValue = userValue;
                                                onChange();
                                            }}/>
                            <Typography align='right' variant='body1' style={{marginLeft: 10}}>Background</Typography>      
                            <ColorPicker initialColor={field.defaultColor} 
                                         disableAlpha={true}
                                         onChange={(color) => {field.defaultColor = color.hex; onChange(); }}/>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'center', marginTop: 25}}>
                            <Button variant='outlined' onClick={onAddColorLogic}>Add Logic</Button>
                        </div>
                
                    </div>
                : null}


            </Paper>;

}



